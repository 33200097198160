import { makeObservable, observable, action, runInAction } from 'mobx';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import Pagination from 'stores/_mobx/options/pagination';
import { dateToLocalTimezone, getDateRangeBounds } from 'utils/DateUtils';
import { calcDateRange } from 'utils/calcDateRange';

const defaultFilterValue: FilterType = {
  technologistId: 0,
  corporateId: 0,
  facilityId: 0,
  physicianId: 0,
  patientFirstName: '',
  patientLastName: '',
  mrn: '',
  pos: 0,
  period: 'M',
  ...calcDateRange('M'),
};

export interface FilterType {
  technologistId: number;
  corporateId: number;
  facilityId: number;
  physicianId: number;
  patientFirstName: string;
  patientLastName: string;
  mrn: string;
  pos: number;
  dosEnd: string;
  dosStart: string;
  period: 'A' | 'TD' | 'W' | 'M';
}

interface LogResponseType {
  corporate: string | null;
  cptcodedesc: string;
  dos: string;
  facilityname: string | null;
  findingsCode: string;
  patientfname: string | null;
  patientlname: string | null;
  patientmrn: string | null;
  physicianfname: string;
  physicianlname: string;
  pos: string | null;
  studyid: string;
  techfname: string | null;
  techlname: string | null;
}

interface LogType extends Omit<LogResponseType, 'studyid'> {
  physician: string;
  studyid: number;
  technologist: string;
}

interface LogResponse {
  count: number;
  data: LogResponseType[];
}

class SummaryFacility {
  fetching: boolean = false;
  logList: LogType[] = [];
  logTotal: number = 0;
  filter: FilterType = defaultFilterValue;

  page: Pagination = new Pagination({ id: 'summary_facility_gridId' });

  constructor() {
    makeObservable(this, {
      fetching: observable,
      logList: observable,
      logTotal: observable,
      filter: observable,

      setFetching: action,
      setFilter: action,
    });
  }

  setFilter(payload: FilterType) {
    this.filter = payload;
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setDefaultFilter() {
    const hasPermissionCorporation =
      UserProfileStore.getUserType() === 'D' ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .includes('corporate');

    const filter = {
      ...this.filter,
      facilityId: UserProfileStore.getFacilityId() || this.filter.facilityId,
      corporationId: hasPermissionCorporation
        ? 0
        : UserProfileStore.getCorporateId(),
    };

    this.setFilter(filter);
  }

  getDefaultFilter() {
    const hasPermissionCorporation =
      UserProfileStore.getUserType() === 'D' ||
      !UserProfileStore.isClientUser() ||
      UserProfileStore.getUser()
        .usertypename.toLowerCase()
        .includes('corporate');

    const filter = {
      ...defaultFilterValue,
      facilityId:
        UserProfileStore.getFacilityId() || defaultFilterValue.facilityId,
      corporationId: hasPermissionCorporation
        ? defaultFilterValue.corporateId
        : UserProfileStore.getCorporateId(),
    };

    return filter;
  }

  async getLogs() {
    const {
      page: { pagination },
      filter,
    } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    const data = {
      skip: pagination.skip,
      pageSize: pagination.pageSize,
      technologist: filter.technologistId || null,
      physician: filter.physicianId || null,
      patientLastName: filter.patientLastName || null,
      patientFirstName: filter.patientFirstName || null,
      facility: filter.facilityId,
      patientMrn: filter.mrn || null,
      corporate: filter.corporateId || 0,
      dosFrom: dos.dateFrom,
      dosTo: dos.dateTo,
      pos: filter.pos || null,
    };

    this.setFetching(true);

    try {
      const response = await apiRequest<LogResponse>({
        url: 'report.TechnicianExam.getSumFacilityDetails',
        data,
      });

      runInAction(() => {
        this.logList = response.data.map((log) => ({
          ...log,
          corporate: log.corporate || '',
          cptcodedesc: log.cptcodedesc || '',
          dos: dateToLocalTimezone({ date: log.dos, dateOnly: true }),
          facilityname: log.facilityname || '',
          findingsCode: log.findingsCode,
          mrn: log.patientmrn || '',
          physician: `${log.physicianfname || ''} ${
            log.physicianlname || ''
          }`.trim(),
          studyid: Number(log.studyid),
          technologist: `${log.techfname || ''} ${log.techlname || ''}`.trim(),
        }));
        this.logTotal = Number(response.count);
      });
    } catch (e) {
      runInAction(() => {
        this.logList = [];
        this.logTotal = 0;
      });
    } finally {
      this.setFetching(false);
    }
  }

  generatePdf = async ({ filter }: { filter: string }) => {
    const data = {
      dataSource: this.logList,
      filter,
    };
    try {
      const response = await apiRequest<string>({
        url: 'pdfcreater.reportPdfGenerator.summaryfacilitypdf',
        data,
      });
      return response;
    } catch (e: any) {
      return '';
    }
  };

  prepareFilterForExport() {
    const { filter } = this;

    const dos = getDateRangeBounds({
      from: filter.dosStart,
      to: filter.dosEnd,
    });

    return {
      ...filter,
      dosStart: dos.dateFrom,
      dosEnd: dos.dateTo,
    };
  }
}

export const storeSummaryFacility = new SummaryFacility();
