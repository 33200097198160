import { useLayoutEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';
import { LayoutSideTitle } from 'components/layout';
import { SpinnerFixed } from 'components/spinner';
import PhysicianCompleteInformationForm from './components/CompleteInformation';

import {
  storePhysician,
  PhysicianFormType,
  PhysicianAddedSuccessResponseType,
} from 'stores/_mobx/clinicianManager/physician';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import { URL_PHYSICIANS } from 'constant/path/clinicalManager';

interface PropsType extends RouteComponentProps {}

const PhysicianAddPage = ({ history }: PropsType) => {
  const { physicianDetailsDefaultValues, fetchingPhysicianDetails } =
    storePhysician;

  const { fetching, passwordOptions, passwordSettings } = storePasswordStrength;

  const [succeedCreatingPhysicianData, setPhysicianData] =
    useState<PhysicianAddedSuccessResponseType>(null);

  const isLoading = fetching || !passwordSettings;

  const handleCloseDialog = () => {
    history.push(URL_PHYSICIANS);
  };

  const handleSubmit = (payload: PhysicianFormType) =>
    storePhysician
      .addPhysician(payload)
      .then(({ isSucceed, errorMessage, physicianInfo }) => {
        if (isSucceed) setPhysicianData(physicianInfo);
        return isSucceed ? null : errorMessage;
      });

  useLayoutEffect(() => {
    if (!passwordSettings) storePasswordStrength.getPasswordSettings();

    return storePhysician.clearPhysicianStore;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LayoutSideTitle title="Create new Physician">
        <Link to={URL_PHYSICIANS} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>

      {isLoading ? (
        <SpinnerFixed />
      ) : (
        <PhysicianCompleteInformationForm
          backUrl={URL_PHYSICIANS}
          mode='A'
          passwordOptions={passwordOptions}
          defaultValues={physicianDetailsDefaultValues}
          fetching={fetchingPhysicianDetails}
          onSubmit={handleSubmit}
        />
      )}

      {succeedCreatingPhysicianData && (
        <Dialog handleClose={handleCloseDialog}>
          <DialogHeader title="User Pin" onClose={handleCloseDialog} />
          <DialogBody>
            <div>
              <p>New Physician was created successfully!</p>
              <p>Pin value: {succeedCreatingPhysicianData.ipin}</p>
            </div>
          </DialogBody>
        </Dialog>
      )}
    </>
  );
};

export default observer(PhysicianAddPage);
