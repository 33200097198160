import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Notification from 'components/modal/Notification';
import Title from 'components/project/common/title';
import DialogPinDisplaying from 'components/project/modals/pinDisplaying';
import ChangeExistingPassword, {
  FormModel,
} from './components/FormChangePassword';

import UserProfileActions from 'actions/UserProfileActions';
import { URL_CHANGE_PASSWORD, URL_LOGIN } from 'constant/path/auth';

interface PropsType {
  userId: string;
  username: string;
  onClose: () => void;
}

const FirstLoginChangePassword = ({ userId, username, onClose }: PropsType) => {
  const [pin, setPin] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const history = useHistory();

  const handleDialogPinClose = () => {
    const credentials = { username, password };
    UserProfileActions.checkLogin(credentials)
      .then((response: any) => {
        const { status } = response?.[0] || {};
        if (status === 'OK') {
          UserProfileActions.pinCheck(
            pin,
            history,
            credentials
          );
        } else if (status === 'P') {
          history.push(URL_CHANGE_PASSWORD);
        } else {
          Notification.danger('Invalid credentials');
          history.push(URL_LOGIN);
        }
      });
  };

  const handleSubmit = ({ password }: FormModel) =>
    UserProfileActions.updateResetPassword(Number(userId), password).then(
      (response) => {
        if (Number(response)) {
          setPassword(password);
          setPin(response);
        } else if (response === 'P') {
          Notification.warning(
            'The password should be different from the last password!'
          );
        } else {
          Notification.danger('The password is invalid!');
        }
      }
    );

  return (
    <>
      <div className="outdated-password">
        <Title title="Change Password" />
        <ChangeExistingPassword
          className="row"
          onCancel={onClose}
          onSubmit={handleSubmit}
        />
      </div>
      {pin && (
        <DialogPinDisplaying
          title="User Pin"
          pin={pin}
          onClose={handleDialogPinClose}
        />
      )}
    </>
  );
};

export default FirstLoginChangePassword;
