import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Notification from 'components/modal/Notification';
import DialogFormRequestForLogin, {
  FormModel as FormModelRequestForLogin,
} from './components/DialogFormRequestForLogin';
import FormLogin, { FormModel as FormModelLogin } from './components/FormLogin';
import FormPin, { FormModel as FormModelPin } from './components/FormPin';
import FirstLoginChangePassword from './FirstLoginChangePassword';

import UserProfileActions from 'actions/UserProfileActions';
import { BASE_URL_FILE_DIR } from 'constant/config';
import DialogResetPassword from './components/dialogResetPassword';

interface PropsType extends RouteComponentProps {}

interface StateType {
  showPin: boolean;
  errorMessage: string;
  requestForLoginShown: boolean;
  forgotPasswordShown: boolean;
  showChangePassword: boolean;
  userId: string;
  logoUrl?: string;
  credentials: {
    username: string;
    password: string;
  };
  username?: string;
}

const initState: StateType = {
  showPin: false,
  errorMessage: '',
  userId: '',
  requestForLoginShown: false,
  forgotPasswordShown: false,
  showChangePassword: false,
  credentials: {
    username: '',
    password: '',
  },
  username: '',
};

export class LoginPage extends React.Component<PropsType, StateType> {
  state = {
    ...initState,
    logoUrl: `${BASE_URL_FILE_DIR}doc_img/images/logo.png?${Date.now()}`,
  };

  handleLogin = (data: FormModelLogin) => {
    const credentials = {
      username: data.username,
      password: data.password,
    };
    this.setState({ credentials });
    return UserProfileActions.checkLogin(data).then((response: any) => {
      const { status, refid, msg, username } = response?.[0] || {};
      if (status === 'OK') {
        this.setState({ errorMessage: '', showPin: true, username });
      } else if (status === 'P') {
        this.setState({
          showChangePassword: true,
          userId: refid,
          username,
        });
      } else {
        this.setState({
          errorMessage: msg || 'Invalid credentials',
        });
      }
    });
  };

  handleCancelPin = () => {
    this.setState({
      showPin: false,
      errorMessage: '',
      credentials: { username: '', password: '' },
    });
  };

  handleSubmitPin = ({ ipin }: FormModelPin) =>
    UserProfileActions.pinCheck(
      ipin,
      this.props.history,
      this.state.credentials
    ).then((response) => {
      if (response) {
        this.setState({ errorMessage: response.pin });
      }
    });

  handleCancelChangePassword = () => {
    this.setState({ showChangePassword: false });
  };

  handleLoadLogoError = () => {
    this.setState({
      logoUrl: `assets/images/logo-loginpage.png?${Date.now()}`,
    });
  };

  handleCloseDialogForgotPassword = () => {
    this.setState({ forgotPasswordShown: false });
  };

  handleSubmitRequestResetPassword = async (data: any) => {
    this.setState({ forgotPasswordShown: false });

    UserProfileActions.requestResetPassword(data)
      .then((response: any) => {
        if (response) {
          Notification.success(response);
        }
      })
      .catch((error: any) => {
        if (typeof error === 'string' && error !== '') {
          Notification.success('Password reset request sent.');
        } else {
          Notification.danger('An error occurred.');
        }
      });
  };

  handleCloseDialogRequestForLogin = () => {
    this.setState({ requestForLoginShown: false });
  };

  handleSubmitRequestForLogin = (data: FormModelRequestForLogin) =>
    UserProfileActions.requestForLogin(data).then((response) => {
      if (response === 'S') {
        Notification.success('Your request has been sent successfully');
        this.handleCloseDialogRequestForLogin();
      } else {
        Notification.warning('Error occurred');
      }
    });

  render() {
    const { state } = this;

    return (
      <>
        <div className="wrapper">
          {state.showChangePassword ? (
            <FirstLoginChangePassword
              userId={this.state.userId}
              username={this.state.username}
              onClose={this.handleCancelChangePassword}
            />
          ) : (
            <div className="login-box" id="login-box">
              <div className="login-logo">
                <img
                  src={this.state.logoUrl}
                  onError={this.handleLoadLogoError}
                  alt="Lattice Pro"
                />
              </div>

              <p className="login-error">{state.errorMessage}</p>

              {this.state.showPin ? (
                <FormPin
                  onCancel={this.handleCancelPin}
                  onSubmit={this.handleSubmitPin}
                />
              ) : (
                <FormLogin onSubmit={this.handleLogin} />
              )}

              <div className="login-links">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ forgotPasswordShown: true });
                  }}>
                  Forgot Password?
                </a>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ requestForLoginShown: true });
                  }}>
                  Request For Login
                </a>
              </div>
            </div>
          )}
        </div>
        {this.state.requestForLoginShown && (
          <DialogFormRequestForLogin
            onSubmit={this.handleSubmitRequestForLogin}
            onClose={this.handleCloseDialogRequestForLogin}
          />
        )}
        {this.state.forgotPasswordShown && (
          <DialogResetPassword
            onSubmit={this.handleSubmitRequestResetPassword}
            onClose={this.handleCloseDialogForgotPassword}
          />
        )}
      </>
    );
  }
}

export default LoginPage;
