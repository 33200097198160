import { makeObservable, observable, action } from 'mobx';

import { apiRequest } from 'services/RequestService';
import Notification from 'components/modal/Notification';

enum ErrorType {
  S = 'S',
  P = 'P',
  E = 'E',
}

const errorsList: Record<ErrorType, string> = {
  S: '',
  P: 'The password should be different from the last password!',
  E: 'Old password is incorrect!',
};

export interface SecurityQuestionPayload {
  refid: number;
  usertype: string;
  answer1: string;
  answer2: string;
}
interface SecurityQuestionResponse {
  refid: number;
  quest_id: string;
  quest1_id: string;
}

interface SecurityQuestionVerifyResponse {
  refid: number;
  user_name?: string;
  user_pass?: string;
  usertype_id?: string;
}

interface ChangePasswordPayload {
  userId: number;
  login: string;
  oldPassword: string;
  newPassword: string;
}

class Credentials {
  pin: string = '';
  questions: [string, string] = ['', ''];
  questionsFetching: boolean = false;
  isQuestionsVerifyPass: boolean = false;
  errorMessage: string = '';

  constructor() {
    makeObservable(this, {
      pin: observable,
      questions: observable,
      questionsFetching: observable,
      isQuestionsVerifyPass: observable,
      errorMessage: observable,

      setPin: action,
      setIsQuestionsVerifyPass: action,
      clearError: action,
      clearQuestions: action,
      clearIsQuestionsVerifyPass: action,
      clearPin: action,
      createPin: action,
      verifyAnswers: action,
      changePassword: action,
      getQuestions: action,
    });
  }

  setPin = (pin: string) => {
    this.pin = pin;
  }

  setIsQuestionsVerifyPass = (isQuestionsVerifyPass: boolean) => {
    this.isQuestionsVerifyPass = isQuestionsVerifyPass;
  }

  clearPin = () => {
    this.pin = '';
  };

  clearError = () => {
    this.errorMessage = '';
  };

  clearQuestions = () => {
    this.questions = ['', ''];
  };

  clearIsQuestionsVerifyPass = () => {
    this.isQuestionsVerifyPass = false;
  }

  getQuestions = async (userId: number, signal?: AbortSignal) => {
    this.clearQuestions();
    this.questionsFetching = true;
    try {
      const [response] = await apiRequest<SecurityQuestionResponse[]>({
        url: 'userlogin.LoginMaster.GetFavoriteQuestion',
        data: [userId],
        signal,
      });
      this.questions = [response.quest_id, response.quest1_id];
    } catch (error) {
    } finally {
      this.questionsFetching = false;
    }
  };

  verifyAnswers = async ({
    refid,
    usertype,
    answer1,
    answer2,
  }: SecurityQuestionPayload) => {
    try {
      const [response] = await apiRequest<SecurityQuestionVerifyResponse[]>({
        url: 'userlogin.LoginMaster.VerifyAnswer',
        data: [refid, usertype, answer1, answer2],
      });

      const isAnswersCorrect = Boolean(response.refid);
      this.errorMessage = isAnswersCorrect
        ? ''
        : 'The given details are incorrect. Please provide proper details.';

      this.setIsQuestionsVerifyPass(isAnswersCorrect);
    } catch (error) {
      this.errorMessage = 'Unknown error. Please, contact your administrator.';
    }
  };

  createPin = async (userId: number) => {
    this.clearPin();

    try {
      const response = await apiRequest<string>({
        url: 'usermaster.UserInfo.RegenerateIpin',
        data: [userId],
      });

      this.pin = response;
    } catch (error) {}
  };

  changePassword = async ({
    userId,
    login,
    oldPassword,
    newPassword,
  }: ChangePasswordPayload) => {
    const data = [userId, login, newPassword, oldPassword];
    try {
      const response = await apiRequest<ErrorType>({
        url: 'userlogin.LoginMaster.LoginChangePassword',
        data,
      });

      const isPasswordChanged = response === ErrorType.S || response[0] === ErrorType.S;

      this.errorMessage =
        response[0] !== ErrorType.S && errorsList[response]
          ? errorsList[response]
          : response[0] === ErrorType.S
            ? errorsList[response[0]]
            : 'Unknown error. Please, contact your administrator.';

      if (isPasswordChanged) {
        Notification.success('Password was changed successfully!');
        this.setPin(response[1]);
      }

      return isPasswordChanged ? response : false;
    } catch (error) {
      return false;
    }
  };
}

export const storeProfileCredentials = new Credentials();
