import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import DialogImporting, { ImportType } from 'page/components/DialogImporting';
import DialogFormResetPassword from 'page/components/DialogFormResetPassword';
import DialogSecurityDetails from 'page/components/DialogSecurityDetails';
import ClientUserPrivilegesPage from 'page/privileges/settings/ClientUserPrivilegesPage';
import Exporter from 'components/project/ExporterNew';
import { LayoutSideTitle } from 'components/layout';
import { Grid, GridControlButton } from 'components/grid';
import { Button, IconButton } from 'components/button';
import DialogConfirm from 'components/modal/dialogConfirm';
import { SpinnerFixed } from 'components/spinner';
import Filter from './components/Filter';

import UserProfileStore from 'stores/UserProfileStore';
import {
  storePhysician,
  PhysicianExtendedType,
  FilterType,
} from 'stores/_mobx/clinicianManager/physician';
import { storeUser } from 'stores/_mobx/users/user';
import { DIR_WITH_EXAMPLE } from 'constant/config';
import { PHYSICIAN as PAGE_ID } from 'constant/pagesId/clinicianManager';
import {
  URL_PHYSICIANS,
  URL_PHYSICIANS_ADD,
} from 'constant/path/clinicalManager';

const columnsName = {
  last_name: true,
  first_name: true,
  user_name: true,
  npi: true,
  email: true,
  mobile: true,
  privilege: true,
  security: true,
  resetPassword: true,
  loginDetails: true,
  controls: true,
  usertypename: false,
  fax: false,
  birthdate: false,
};

const visibleColumnsByDefault = new Map(Object.entries(columnsName));

interface PrivilegesType {
  userType: number;
  userId: number;
}

const PhysiciansListPage = () => {
  const gridRef = useRef<Grid>();

  const {
    fetchingExtendedPhysicians: fetching,
    extendedPhysiciansList,
    extendedPhysiciansTotal,
    permission,
    filter,
    idsForChangeStatus,
    page: { pagination, setPagination, setPaginationToStart },
  } = storePhysician;

  const { fetchingSecurity, securityDetails, passwordResetDetails } = storeUser;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExporterVisible, toggleExporter] = useState<boolean>(false);

  const [isImportVisible, toggleImport] = useState<boolean>(false);

  const [selected, setSelected] = useState<number[]>([]);

  const [privileges, setPrivileges] = useState<null | PrivilegesType>(null);

  const columns = useMemo(() => {
    const columns: Array<{
      name: string;
      title: string;
      className?: string;
      render?: (v: any, row: any) => any;
    }> = [
      { name: 'last_name', title: 'Last Name' },
      { name: 'first_name', title: 'First Name' },
      { name: 'user_name', title: 'Username' },
      { name: 'usertypename', title: 'User Type' },
      { name: 'npi', title: 'NPI' },
      { name: 'birthdate', title: 'Date Of Birth' },
      { name: 'email', title: 'Email' },
      { name: 'fax', title: 'Fax' },
      { name: 'mobile', title: 'Cell Phone' },
      permission.configurePrivileges && {
        name: 'privilege',
        title: 'Privilege',
        className: 'text-center',
        render: (value: undefined, row: PhysicianExtendedType) =>
          row.usertype_id === 8 ? null : (
            <IconButton
              className="fs-4 text-primary"
              onClick={() => {
                setPrivileges({
                  userType: row.usertype_id,
                  userId: row.refid,
                });
              }}>
              <i className="bi bi-person-gear" />
            </IconButton>
          ),
      },
      permission.viewCredentials && {
        name: 'security',
        title: 'Security',
        render: (
          value: undefined,
          { usertype_id, UserId, flagViewPassword }: PhysicianExtendedType
        ) =>
          flagViewPassword && (
            <IconButton
              onClick={() => {
                storeUser.getSecurityDetails({
                  userType: usertype_id,
                  userId: UserId,
                });
              }}>
              <i className="icon icon-security" />
            </IconButton>
          ),
      },
      filter.status === 'A' && permission.resetPassword
        ? {
            name: 'resetPassword',
            title: 'Password',
            className: 'text-center',
            render: (
              value: undefined,
              { refid, user_name }: PhysicianExtendedType
            ) => (
              <a
                href="/"
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  storeUser.setPasswordResetDetails({
                    userId: refid,
                    userName: user_name,
                  });
                }}
                className="color-black fw-bold">
                Reset
              </a>
            ),
          }
        : null,
      filter.status === 'A'
        ? {
            name: 'control',
            title: 'Actions',
            className: 'width-50',
            render: (value: undefined, { refid }: PhysicianExtendedType) => (
              <div className="control">
                {permission.editPhysician && (
                  <Link
                    className="bi bi-pencil"
                    to={`${URL_PHYSICIANS}/edit/${refid}`}
                  />
                )}
                {/* Temporary deactivated according to task "feature/LPFE-856"  */}
                {/* {AccessUtils.checkAccess(PAGE_ID.DELETE) &&
                    <IconButton onClick={this.showDeleteCodePopup(data.refid)}>
                      <i className="bi bi-trash" />
                    </IconButton>
                    
                  } */}
              </div>
            ),
          }
        : null,
    ];

    return columns.filter((el) => Boolean(el));
  }, [permission, filter.status]);

  const exportColumns = useMemo(() => {
    const exportColumns = columns.filter((column) => column.name !== 'resetPassword');

    return exportColumns.filter((column) => Boolean(column));
  }, [columns]);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleImport = () => {
    toggleImport((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExporter((state) => !state);
  };

  const fetchData = () => {
    gridRef.current.clearSelection();
    storePhysician.getExtendedPhysiciansList();
  };

  const handleApproveChangeStatus = () => {
    storePhysician.changePhysicianStatusMain().then((isSucceed) => {
      if (isSucceed) {
        fetchData();
      }
    });
  };

  const handleClosePrivileges = () => {
    setPrivileges(null);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storePhysician.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    fetchData();
  }, [pagination]);

  useLayoutEffect(() => {
    storePhysician.checkPermission();

    return storePhysician.cancelAbortController;
  }, []);

  return privileges ? (
    <ClientUserPrivilegesPage
      userType={privileges.userType}
      userId={privileges.userId}
      backButton={handleClosePrivileges}
    />
  ) : (
    <>
      <LayoutSideTitle title="Physicians Page" appId={PAGE_ID.PAGE}>
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter
        fetching={fetching}
        isVisible={isFilterVisible}
        onSubmit={handleChangeFilter}
      />

      <Grid
        selectId="refid"
        id="physicians_users_grid"
        columns={columns}
        ref={gridRef}
        dataSource={extendedPhysiciansList}
        dataSourceCount={extendedPhysiciansTotal}
        pagination={pagination}
        shownColumns={visibleColumnsByDefault}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            {permission.addPhysician && (
              <GridControlButton title="Add" url={URL_PHYSICIANS_ADD} />
            )}
            {permission.toggleState && (
              <GridControlButton
                title={filter.status === 'A' ? 'Deactivate' : 'Activate'}
                disabled={!selected.length}
                onClick={() => storePhysician.setIdsForChangeStatus(selected)}
              />
            )}
            <GridControlButton title="Export" onClick={handleToggleExport} />
            {permission.importPhysicians && (
              <GridControlButton title="Import" onClick={handleToggleImport} />
            )}
          </>
        }
      />

      {securityDetails && (
        <DialogSecurityDetails
          details={securityDetails}
          onClose={storeUser.clearSecurityDetails}
        />
      )}

      {passwordResetDetails && (
        <DialogFormResetPassword
          onClose={storeUser.clearPasswordResetDetails}
        />
      )}

      {idsForChangeStatus.length ? (
        <DialogConfirm
          onCancel={storePhysician.clearIdsForChangeStatus}
          onApprove={handleApproveChangeStatus}>
          {filter.status === 'A'
            ? 'Deactivate selected person(s)?'
            : 'Activate selected person(s)?'}
        </DialogConfirm>
      ) : null}

      {isImportVisible && (
        <DialogImporting
          examplePath={`${DIR_WITH_EXAMPLE}/physician.csv`}
          type={ImportType.physician}
          onImportSucceed={fetchData}
          onClose={handleToggleImport}
        />
      )}

      {isExporterVisible && (
        <Exporter
          exporter="PhysicianExporter"
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
            'Physician_Users'
          }
          columns={exportColumns}
          gridRef={gridRef}
          dataSource={extendedPhysiciansList}
          filter={filter}
          filterForRequest={storePhysician.prepareFilterForExport()}
          pdfExporter={storePhysician.generatePdf}
          onClose={handleToggleExport}
        />
      )}

      {(fetching || fetchingSecurity) && <SpinnerFixed />}
    </>
  );
};

export default observer(PhysiciansListPage);
