import { RouteComponentProps } from 'react-router-dom';

import UserProfileActions from 'actions/UserProfileActions';
import UserProfileStore from 'stores/UserProfileStore';

import Title from 'components/project/common/title';
import Notification from 'components/modal/Notification';
import FormSecurityQuestions, { FormValue } from 'page/authorization/login/components/formSecurityQuestions';

import { URL_LOGOUT } from 'constant/path/auth';

const SecurityQuestionsPage = ({ history }: RouteComponentProps) => {
  const handleCancel = () => {
    history.replace(URL_LOGOUT);
  };

  const onSubmit = (model: FormValue) => {
    const userId = UserProfileStore.getUserId();

    if (model.secQues1 === model.secQues2) {
      Notification.warning('The security questions must be different!');
      return Promise.resolve();
    }

    return UserProfileActions.saveSecurityQuestions(
      userId,
      model.secQues1,
      model.answer1,
      model.secQues2,
      model.answer2
    ).then((response) => {
      if (response === 'S') {
        Notification.success('The security questions have been successfully set!');
        history.push('/');
      } else {
        Notification.danger('The security questions are invalid!');
      }
    });
  };

  return (
    <div className="small-form">
      <Title title="Security Questions" />

      <FormSecurityQuestions
        className="row"
        onSubmit={onSubmit}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default SecurityQuestionsPage;