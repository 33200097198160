import Dialog, { DialogBody, DialogHeader } from 'components/modal/dialog';

import FormUserAuthResetPassword, { FormModel } from '../FormResetPassword';

interface PropsType {
  onSubmit: (data: FormModel) => Promise<any>;
  onClose: () => void;
}

const DialogResetPassword = ({ onSubmit, onClose }: PropsType) => {
  const dialogTitle = 'Reset Password';

  return (
    <Dialog handleClose={onClose}>
      <DialogHeader title={dialogTitle} onClose={onClose} />
      <DialogBody>
        <FormUserAuthResetPassword onSubmit={onSubmit} />
      </DialogBody>
    </Dialog>
  );
};

export default DialogResetPassword;
