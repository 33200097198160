import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { storeSecurityQuestion } from 'stores/_mobx/systemSetup/masterSetting/securityQuestion';

import { Input } from 'components/form/textField';
import { Select } from 'components/form/select';
import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';
import resolver from './validation';

export interface FormValue {
  secQues1: number;
  secQues2: number;
  answer1: string;
  answer2: string;
}

export const defaultValues: FormValue = {
  secQues1: undefined,
  secQues2: undefined,
  answer1: '',
  answer2: '',
};

interface PropsType {
  className?: string;
  onCancel?: () => void;
  onSubmit: (model: FormValue) => Promise<void>;
}

const FormSecurityQuestions = ({
  className,
  onCancel,
  onSubmit,
}: PropsType) => {
  const { fetchingOptions: isFetching, options } = storeSecurityQuestion;

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<FormValue>({
    defaultValues,
    resolver,
  });

  const wrapperCn = clsx(className, { 'on-ajax': isFetching });

  useEffect(() => {
    storeSecurityQuestion.getQuestionOption();
  }, []);

  return (
    <form
      className={wrapperCn}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Select
        name="secQues1"
        label="Security Question 1"
        options={options}
        control={control}
        required
      />
      <Input
        name="answer1"
        label="Answer 1"
        control={control}
        required
      />
      <Select
        name="secQues2"
        label="Security Question 2"
        options={options}
        control={control}
        required
      />
      <Input
        name="answer2"
        label="Answer 2"
        control={control}
        required
      />
      <ControlsLayout alignX="between">
        {onCancel && (
          <Button
            variant="danger"
            text="Cancel"
            disabled={isSubmitting || isFetching}
            onClick={onCancel}
          />
        )}

        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty || isFetching}
        />
      </ControlsLayout>
    </form>
  );
};

export default observer(FormSecurityQuestions);