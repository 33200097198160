import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import FormSecurityQuestion from 'page/authorization/login/components/FormSecurityQuestion';
import { SpinnerFixed } from 'components/spinner';
import Title from 'components/project/common/title';
import DialogPinDisplaying from 'components/project/modals/pinDisplaying/';
import useRequest from 'components/hook/useRequest';
import FormChangePassword, { FormModel } from './components/FormChangePassword';

import userProfileStore from 'stores/UserProfileStore';
import { storeProfileCredentials } from 'stores/_mobx/profile/credential';
import { CHANGE_LOGIN_DETAIL as PAGE_ID } from 'constant/pagesId/personalProfile';
import { URL_LOGOUT } from 'constant/path/auth';

interface PropsType extends RouteComponentProps {}

const ChangeLoginDetailsPage = ({ history }: PropsType) => {
  const {
    questions,
    pin,
    errorMessage,
    isQuestionsVerifyPass,
    clearPin,
    createPin,
    getQuestions,
    changePassword,
    verifyAnswers,
    clearIsQuestionsVerifyPass,
  } = storeProfileCredentials;

  const { fetching, controlledAction: getQuestionsControlled } =
    useRequest<number>(getQuestions);

  const handleRegeneratePIN = () => {
    createPin(userProfileStore.getUserId());
  };

  const handlePasswordChange = (model: FormModel) => {
    const payload = {
      userId: userProfileStore.getUserId(),
      login: userProfileStore.getLogin(),
      ...model,
    };
    return changePassword(payload).then((response) => {
      const isResponseSuccessful = Array.isArray(response) && response[0] === 'S';

      if (response === 'S' || isResponseSuccessful) {
        return isResponseSuccessful ? response[0] : response;
      } else if (typeof response === 'boolean' && response) {
        history.push(URL_LOGOUT);
      }
    });
  };

  const handleSubmitSecurityQuestion = (model: {
    answer1: string;
    answer2: string;
  }) => {
    const payload = {
      refid: userProfileStore.getUserId(),
      usertype: userProfileStore.getUserType(),
      ...model,
    };
    return verifyAnswers(payload);
  };

  useEffect(() => {
    getQuestionsControlled(userProfileStore.getUserId());

    return clearIsQuestionsVerifyPass;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fetching ? (
    <SpinnerFixed />
  ) : (
    <>
      <Title title="Change Login Details" appId={PAGE_ID.PAGE} />
      {errorMessage && (
        <div className="text-danger font-large mb-4">{errorMessage}</div>
      )}
      <div className="col-md-8 col-lg-6 col-xl-5">
        {isQuestionsVerifyPass ? (
          <FormChangePassword
            onSubmit={handlePasswordChange}
            onRegeneratePIN={handleRegeneratePIN}
          />
        ) : (
          <FormSecurityQuestion
            questions={questions}
            onSubmit={handleSubmitSecurityQuestion}
          />
        )}
      </div>
      {pin && <DialogPinDisplaying pin={pin} onClose={clearPin} />}
    </>
  );
};

export default observer(ChangeLoginDetailsPage);
