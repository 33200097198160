import { makeObservable, action, observable, computed } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';
import UserProfileStore from 'stores/UserProfileStore';
import { OptionsResponse as FacilityOptionsResponse } from 'stores/_mobx/dropDown/facility';
import { storePrefix } from 'stores/_mobx/systemSetup/masterSetting/prefix';
import { storeSuffix } from 'stores/_mobx/systemSetup/masterSetting/suffix';
import { storeDesignation } from 'stores/_mobx/systemSetup/masterSetting/designation';
import { storePhysician } from 'stores/_mobx/clinicianManager/physician';
import { storeImport } from 'stores/_mobx/import';
import { convertDateInStingToLocalTimezone } from 'utils/DateUtils';
import { BASE_URL_FILE_DIR } from 'constant/config';

const defaultAvatars = ['user_female.jpg', 'default_user.jpg'];

const getAvatarPath = (currentPath: string, gender: GenderType) => {
  const isDefaultAvatar = currentPath
    ? defaultAvatars.includes(currentPath)
    : true;

  const tempPath = gender === 'F' ? defaultAvatars[0] : defaultAvatars[1];

  const path = isDefaultAvatar
    ? ''
    : UserProfileStore.getUserType() === 'D'
    ? `${BASE_URL_FILE_DIR}doc_img/providerimage/`
    : `${BASE_URL_FILE_DIR}doc_img/staffimage/`;

  const avatarPath = isDefaultAvatar
    ? `/assets/images/${currentPath || tempPath}`
    : `${path}${currentPath}`;

  return avatarPath;
};

type MailType = 'I' | 'E' | 'B' | 'N';

type GenderType = 'M' | 'F' | 'U';

export interface OptionType {
  label: string;
  value: number;
}

export interface EmailTemplateResponse {
  appRefID: string;
  selected: boolean;
  template_code: string;
  template_name: string;
}

export interface UserInfoResponseType {
  Region: any[];
  comment: string;
  corporate_id: number;
  created_by: number;
  created_dt: string;
  emailTemplates: EmailTemplateResponse[];
  facilityArr: FacilityOptionsResponse[];
  facility_id: number;
  floorArr: [];
  floor_id: number;
  licenses: [];
  linked_url: string;
  location: null;
  location_id: number;
  refid: number;
  region: null;
  state: null;
  user_id: number;
  user_name: string;
  user_pass: string;
  user_title: string;
  user_type: string;
  usertpeval: string;
  usertype_id: number;
}

interface StaffResponseType {
  birthdate: string;
  designation: string;
  email: string;
  emergency_name: string;
  emergency_phone: string;
  fax: string;
  first_name: string;
  gender: GenderType;
  homeaddress: string;
  homecity: string;
  homephone: string;
  homestate: string;
  homezipcode: string;
  last_name: string;
  mail_type: MailType;
  middle_name: string;
  mobile: string;
  mobileop_id: string;
  mobileop_name: string;
  officeaddress: string;
  officecity: string;
  officestate: string;
  offzipcode: string;
  other_documents: { dir1: []; dir2: []; dir3: [] };
  recordsAccess: 'A' | 'M';
  refid: number;
  salutation: string;
  serviceuser_privilege: string;
  spouse_name: string;
  ssn: string;
  staff_image_path: string;
  staff_sign_path: string | null;
  suffix: string;
  tech_privilege: 'Y';
  user_title: string;
  work_phone: string;
}

interface PhysicianResponseType {
  StateVal: [];
  birthdate: string;
  cell: string;
  clia_number: string;
  co_sign_required: string;
  dea: string;
  ein: string;
  ein_name: string;
  email: string;
  email_notify: string;
  emailnotify_period: string;
  emergency_name: string;
  emergency_phone: string;
  fax: string;
  first_name: string;
  gender: GenderType;
  home_address: string;
  home_phone: string;
  homecity: string;
  homestate: string;
  homezipcode: string;
  last_name: string;
  mail_type: MailType;
  middle_name: string;
  mobileop_id: string;
  mobileop_name: string;
  notify_supervisor: string;
  npi: string;
  office_address: string;
  officecity: string;
  officestate: string;
  officezipcode: string;
  pecos: 'Y' | 'N';
  phy_group: string;
  prefix: string;
  provider_img: string;
  provider_level: string;
  provider_sig_path: string;
  provider_status: 'A';
  qualification: string;
  referring_nos: string;
  refid: string;
  secemail: string;
  specialty: string;
  spouse_name: string;
  stateIdVal: number;
  stateLisence: string;
  statearr: [];
  supervisedGroup: number;
  supervisor: string;
  taxonomy_code: string;
  taxonomy_code1: number;
  upin: string;
  website: string;
  work_phone: string;
}

export interface ProfileDetailsType {
  qualification: number;
  qualificationName: string;
  prefix: number;
  salutation: string;
  floor_id: number;
  userinforefid: number;
  cell: string;
  creatid: number;
  ssn: string;
  mobileop_id: number;
  facilityid: number;
  office_address: string;
  home_address: string;
  last_name: string;
  homecity: string;
  homestate: string;
  mail_type: MailType;
  homezipcode: string;
  officecity: string;
  officestate: string;
  email: string;
  gender: GenderType;
  comment: string;
  refid: number;
  linkid: string;
  officezipcode: string;
  home_phone: string;
  avatar: string | File;
  fax: string;
  emergency_name: string;
  first_name: string;
  dateofbirth: string;
  emergency_phone: string;
  middle_name: string;
  locationid: number;
  spouse_name: string;
  work_phone: string;
  designation: number;
  sig_path_staff: string;

  emailTemplates: number[];
  userType: string;
  facilityName: string;
  region: string;
  genderName: string;
  designationName: string;
}

interface StaffUserPayloadType {
  qualification: number;
  prefix: number;
  username: string;
  floor_id: number;
  userinforefid: number;
  secq2: any;
  cell: string;
  creatid: number;
  ssn: string;
  secq1: string;
  mobileop_id: number;
  facilityid: number;
  office_address: string;
  home_address: string;
  last_name: string;
  homecity: string;
  homestate: string;
  mail_type: string;
  homezipcode: string;
  officecity: string;
  officestate: string;
  email: string;
  gender: string;
  comment: string;
  refid: number;
  linkid: string;
  officezipcode: string;
  home_phone: string;
  paswrd: string;
  usernm: string;
  oldimg: string;
  img_path: string | File;
  fax: string;
  emergency_name: string;
  first_name: string;
  dateofbirth: string;
  emergency_phone: string;
  middle_name: string;
  locationid: number;
  spouse_name: string;
  usetyp: number;
  work_phone: string;
  designation: number;
  sig_path_staff: string;
}

interface UserIdsType {
  refid: number;
  userId: number;
  userTypeId: number;
}

class MyProfile {
  fetchingDetails: boolean = false;
  fetchingOptions: boolean = false;
  details: ProfileDetailsType | null = null;
  emailTemplatesOption: OptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetchingDetails: observable,
      fetchingOptions: observable,
      details: observable,
      emailTemplatesOption: observable,

      fetching: computed,

      setFetchingDetails: action,
      setFetchingOptions: action,
      setDetails: action,
      setEmailTemplateOption: action,
      clearStore: action.bound,
    });
  }

  setFetchingDetails(fetching: boolean) {
    this.fetchingDetails = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setDetails(details: ProfileDetailsType) {
    this.details = details;
  }

  setEmailTemplateOption(options: OptionType[]) {
    this.emailTemplatesOption = options;
  }

  clearStore() {
    this.details = null;
    this.emailTemplatesOption = [];
  }

  get fetching() {
    return this.fetchingDetails || this.fetchingOptions;
  }

  async getEmailTemplates(data: UserIdsType) {
    this.setFetchingOptions(true);
    try {
      const response = await apiRequest<EmailTemplateResponse[]>({
        url: 'permission.PermissionInfo.LoadTemplateList',
        data: { userId: data.refid, userTypeId: data.userTypeId },
      });

      const options = response
        .filter(({ template_name }) => Boolean(template_name))
        .map((el) => ({
          label: convertDateInStingToLocalTimezone(el.template_name),
          value: Number(el.appRefID),
        }));

      this.setEmailTemplateOption(options);
    } catch (e: any) {
      this.setEmailTemplateOption([]);
    } finally {
      this.setFetchingOptions(false);
    }
  }

  async getUserInfo(data: Omit<UserIdsType, 'refid'>) {
    try {
      const [response] = await apiRequest<[UserInfoResponseType]>({
        url: 'usermaster.UserInfo.GetViewUserInfo',
        data,
      });
      return response;
    } catch (e: any) {
      return null;
    }
  }

  async getStaffInfo(data: Omit<UserIdsType, 'refid'>) {
    try {
      const [response] = await apiRequest<[StaffResponseType]>({
        url: 'usermaster.StaffInfo.GetViewStaffInfo',
        data,
      });

      return response;
    } catch (e: any) {
      return null;
    }
  }

  async getPhysicianInfo(userId: number) {
    try {
      const [response] = await apiRequest<[PhysicianResponseType]>({
        url: 'usermaster.ProviderInfo.GetViewProviderInfo',
        data: [userId],
      });

      return response;
    } catch (e: any) {
      return null;
    }
  }

  async getUserProfile({ refid, ...data }: UserIdsType) {
    this.setFetchingDetails(true);

    const userInfo = await this.getUserInfo({ ...data, userId: refid });

    const isPhysician = userInfo.usertype_id === 2;

    const additionalInfo = isPhysician
      ? await this.getPhysicianInfo(data.userId)
      : await this.getStaffInfo(data);

    // @ts-ignore
    const promisePrefix = Number(additionalInfo.salutation)
      ? // @ts-ignore
        storePrefix.getPrefix(additionalInfo.salutation)
      : Promise.resolve(null as null);

    // @ts-ignore
    const promiseSuffix = Number(additionalInfo.suffix)
      ? // @ts-ignore
        storeSuffix.getSuffix(additionalInfo.suffix)
      : Promise.resolve(null as null);

    // @ts-ignore
    const promiseDesignation = Number(additionalInfo.designation)
      ? // @ts-ignore
        storeDesignation.getDesignation(additionalInfo.designation)
      : Promise.resolve(null as null);

    const [prefix, suffix, designation] = await Promise.all([
      promisePrefix,
      promiseSuffix,
      promiseDesignation,
    ]);

    const emailTemplates = userInfo.emailTemplates
      .filter((el) => el.selected)
      .map(({ appRefID }) => Number(appRefID));

    const facilityName =
      userInfo.facilityArr.find(
        (facility) => Number(facility.data) === userInfo.facility_id
      )?.label || '';

    const currentAvatarPath = isPhysician
      ? // @ts-ignore
        additionalInfo.provider_img
      : // @ts-ignore
        additionalInfo.staff_image_path;

    const avatar = getAvatarPath(currentAvatarPath, additionalInfo.gender);

    const signature = isPhysician
      ? // @ts-ignore
        additionalInfo.provider_sig_path
      : // @ts-ignore
        additionalInfo.staff_sign_path;

    const details = {
      // @ts-ignore
      qualification: Number(additionalInfo.suffix) || 0,
      qualificationName: suffix?.name || '',
      // @ts-ignore
      prefix: Number(additionalInfo.salutation) || 0,
      salutation: prefix?.prefix_nm || '',
      floor_id: userInfo.floor_id,
      userinforefid: userInfo.refid,
      // @ts-ignore
      cell: isPhysician ? additionalInfo.cell : additionalInfo.mobile,
      creatid: userInfo.created_by,
      // @ts-ignore
      ssn: additionalInfo.ssn || '',
      mobileop_id: Number(additionalInfo.mobileop_id),
      facilityid: userInfo.facility_id,
      office_address: isPhysician
        ? // @ts-ignore
          additionalInfo.office_address
        : // @ts-ignore
          additionalInfo.officeaddress,
      home_address: isPhysician
        ? // @ts-ignore
          additionalInfo.home_address
        : // @ts-ignore
          additionalInfo.homeaddress,
      last_name: additionalInfo.last_name,
      homecity: additionalInfo.homecity,
      homestate: additionalInfo.homestate,
      mail_type: additionalInfo.mail_type,
      homezipcode: additionalInfo.homezipcode,
      officecity: additionalInfo.officecity,
      officestate: additionalInfo.officestate,
      email: additionalInfo.email,
      gender: additionalInfo.gender,
      comment: userInfo.comment,
      refid: data.userId,
      linkid: userInfo.linked_url,
      officezipcode: isPhysician
        ? // @ts-ignore
          additionalInfo.officezipcode
        : // @ts-ignore
          additionalInfo.offzipcode,
      home_phone: isPhysician
        ? // @ts-ignore
          additionalInfo.home_phone
        : // @ts-ignore
          additionalInfo.homephone,
      avatar,
      fax: additionalInfo.fax,
      emergency_name: additionalInfo.emergency_name,
      first_name: additionalInfo.first_name,
      dateofbirth: additionalInfo.birthdate,
      emergency_phone: additionalInfo.emergency_phone,
      middle_name: additionalInfo.middle_name,
      locationid: userInfo.location_id,
      spouse_name: additionalInfo.spouse_name,
      work_phone: additionalInfo.work_phone,
      // @ts-ignore
      designation: isPhysician ? 0 : Number(additionalInfo.designation),
      designationName: designation?.name || 'None',
      sig_path_staff: signature
        ? `${BASE_URL_FILE_DIR}doc_img/providersignature/${signature}`
        : '',
      emailTemplates,
      facilityName,
      userType: userInfo.user_type,
      region: userInfo.Region[0]?.region || '',
      genderName:
        additionalInfo?.gender === 'F'
          ? 'Female'
          : additionalInfo?.gender === 'M'
          ? 'Male'
          : 'Unknown',
    };

    this.setDetails(details);

    this.setFetchingDetails(false);
  }

  async updateEmailTemplates(userId: number, templates: number[]) {
    const emailTemplates = templates.map((id) => ({
      appRefID: id,
      selected: true,
    }));
    try {
      const response = await apiRequest<'S' | 'SE' | null>({
        url: 'permission.PermissionInfo.saveSubscriber',
        data: [userId, emailTemplates],
      });
      return !response ? 'S' : response;
    } catch (e: any) {
      return '';
    }
  }

  async updateStaffInfo({
    avatar,
    ...data
  }: Omit<
    ProfileDetailsType,
    | 'emailTemplates'
    | 'userType'
    | 'facilityName'
    | 'region'
    | 'genderName'
    | 'designationName'
    | 'qualificationName'
  >) {
    const currentUser = UserProfileStore.getState().userProfile;

    const isNewAvatarSet = typeof avatar !== 'string';

    try {
      const fileName = isNewAvatarSet
        ? await storeImport.uploadFile({ file: avatar as File })
        : !avatar
        ? 'deleted'
        : '';

      const payload: StaffUserPayloadType = {
        ...data,
        img_path: fileName,
        secq2: currentUser.sec_ques2,
        secq1: currentUser.sec_ques1,
        paswrd: currentUser.user_pass,
        usernm: currentUser.username,
        username: currentUser.username,
        usetyp: Number(currentUser.usertype_id),
        oldimg: (this.details?.avatar as string).replace(/^\/.*\//, ''),
      };

      const response = await apiRequest<'X' | 'S' | 'C'>({
        url: 'usermaster.StaffInfo.EditStaffInfo',
        data: [payload],
      });

      if (response === 'C') {
        return Promise.resolve('C');
      }

      return response;
    } catch (error) {
      return Promise.resolve('S');
    }
  }

  updateProfile = async ({
    emailTemplates,
    userType,
    facilityName,
    region,
    genderName,
    designationName,
    qualificationName,
    ...payload
  }: ProfileDetailsType) => {
    this.setFetchingDetails(true);

    const userId = payload.userinforefid;

    const isPhysician = userType === 'D';

    try {
      const promiseTemplates = this.updateEmailTemplates(
        userId,
        emailTemplates
      );

      const promiseProfile = isPhysician
        ? storePhysician.updatePhysician(payload)
        : this.updateStaffInfo(payload);

      const [templatesResponse, profileResponse] = await Promise.all([
        promiseTemplates,
        promiseProfile,
      ]);

      if (profileResponse === 'C') {
        Notification.warning('This email is already in use!');
        return false;
      }

      if (templatesResponse === 'S' && profileResponse === 'S') {
        Notification.success('Your profile was updated!');
        return true;
      }

      throw Error('');
    } catch (e: any) {
      Notification.warning('Error occurred!');
      return false;
    } finally {
      this.setFetchingDetails(false);
    }
  };
}

export const storeMyProfile = new MyProfile();
