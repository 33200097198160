import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

import { ControlsLayout } from 'components/layout';
import { Button } from 'components/button';
import { InputPassword } from 'components/form/textField';
import validation from './validation';

import { storeProfileCredentials } from 'stores/_mobx/profile/credential';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import generateCustomPassword from 'utils/generatePassword';

const defaultValues: FormModel = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};

export interface FormModel {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

interface PropsType {
  onSubmit: (data: FormModel) => Promise<any>;
  onRegeneratePIN: () => void;
}

const FormChangePassword = ({ onSubmit, onRegeneratePIN }: PropsType) => {
  const { fetching, passwordOptions } = storePasswordStrength;

  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    handleSubmit,
    setValue,
  } = useForm({ defaultValues, resolver: validation(passwordOptions) });

  const handleReset = () => {
    reset(defaultValues);
    storeProfileCredentials.clearError();
  };

  const handleGeneratePassword = () => {
    const value = generateCustomPassword();
    setValue('newPassword', value, { shouldValidate: true });
  };

  const handleSubmitChangePassword = handleSubmit((data: FormModel) =>
    onSubmit(data).then((response) => {
      if (response === 'S') {
        reset(defaultValues);
      }
    })
  );

  useEffect(() => {
    if (!fetching) storePasswordStrength.getPasswordSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      onSubmit={handleSubmitChangePassword}
      className={fetching ? 'on-ajax' : ''}>
      <InputPassword
        name="oldPassword"
        label="Old password"
        required
        control={control}
      />

      <InputPassword
        name="newPassword"
        label="New password"
        className="col"
        required
        control={control}
        buttons={
          <Button
            title="Generate"
            variant="warning"
            onClick={handleGeneratePassword}>
            <i className="bi bi-key" />
          </Button>
        }
      />

      <InputPassword
        name="newPasswordConfirm"
        label="Repeat new password"
        required
        control={control}
      />
      <ControlsLayout alignX="right">
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
        <Button
          className="btn btn-primary"
          text="Reset"
          variant="warning"
          disabled={isSubmitting}
          onClick={handleReset}
        />
        <Button
          className="btn btn-primary"
          text="Regenerate IPIN"
          onClick={onRegeneratePIN}
        />
      </ControlsLayout>
    </form>
  );
};

export default observer(FormChangePassword);
