import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { storeAuthorization } from 'stores/_mobx/auth';
import { apiRequest, apiFileRequest } from 'services/RequestService';
import { BACKEND_ROOT } from 'constant/config';

export enum ImportType {
  physician = 'Physicians',
  facility = 'Facilities',
}

interface ErrorType {
  faultCode: number;
  faultString: string;
}

interface ImportPayloadType {
  file: File;
  importType: ImportType;
}

const errorParsing = (error: ErrorType) => {
  if (error.faultCode === 422) {
    return Object.entries(JSON.parse(error.faultString))
      .map(([key, value]) => `${key}: ${value}`)
      .join('; ');
  }
  return error.faultString;
};

class Import {
  uploading: boolean = false;

  constructor() {
    makeObservable(this, {
      uploading: observable,

      setUploading: action,
    });
  }

  setUploading(uploading: boolean) {
    this.uploading = uploading;
  }

  importFile = async ({ file, importType }: ImportPayloadType) => {
    this.setUploading(true);

    const instance =
      importType === ImportType.physician ? 'Physicians' : 'Facilities';

    const sendRequest =
      importType === ImportType.physician
        ? this.uploadPhysicians
        : this.uploadFacilities;

    const formData = new FormData();

    formData.append('Filedata', file);

    try {
      const fileName = await apiFileRequest<string>({
        url: 'temppath/tempupload.php',
        data: formData,
      });

      const errorMessage = await sendRequest(fileName);

      return errorMessage;
    } catch (e: any) {
      Notification.danger(`${instance} import error!`);
      return '';
    } finally {
      this.setUploading(false);
    }
  };

  async uploadFacilities(fileName: string) {
    try {
      await apiRequest<'S'>({
        url: 'usermaster.Physician.importFacilities',
        data: [fileName],
      });
      Notification.success('Facilities were imported successfully!');
      return '';
    } catch (e: any) {
      return errorParsing(e);
    }
  }

  async uploadPhysicians(fileName: string) {
    try {
      await apiRequest<'S'>({
        url: 'usermaster.Physician.importPhysicians',
        data: [fileName],
      });
      Notification.success('Physicians were imported successfully!');
      return '';
    } catch (e: any) {
      return errorParsing(e);
    }
  }

  async uploadFile({
    url = 'temppath/tempupload.php',
    file,
  }: {
    url?: string;
    file: File;
  }) {
    const formData = new FormData();

    formData.append('Filedata', file);

    try {
      const fileName = await apiFileRequest<string>({
        url,
        data: formData,
      });

      return fileName;
    } catch (e: any) {
      return '';
    }
  }
  async uploadFileNewApi<T>({
    url,
    payload,
  }: {
    url: string;
    payload: Record<string, any>;
  }): Promise<T | null> {
    const formData = new FormData();

    for (let fieldName in payload) {
      formData.append(fieldName, payload[fieldName]);
    }

    const headerParams = storeAuthorization.getParamsForHeader();

    try {
      const fileUploadResponse = await fetch(`${BACKEND_ROOT}/${url}`, {
        method: 'POST',
        body: formData,
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${storeAuthorization.getAccessTokenFromLocalStorage()}`,
          ...headerParams,
        },
      });

      const response = await fileUploadResponse.json();

      return response;
    } catch (e: any) {
      return null;
    }
  }
}

export const storeImport = new Import();
