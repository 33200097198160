import React, { useEffect, useLayoutEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import Notification from 'components/modal/Notification';
import DialogPinDisplaying from 'components/project/modals/pinDisplaying';
import FormChangePassword from './components/FormChangePassword';

import UserProfileActions from 'actions/UserProfileActions';
import { storeAuthorization } from 'stores/_mobx/auth';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import { BASE_URL_FILE_DIR } from 'constant/config';
import { URL_CHANGE_PASSWORD, URL_LOGIN } from 'constant/path/auth';

interface PropsType extends RouteComponentProps {}

const ResetPasswordPage = ({ history }: PropsType) => {
  const {
    passwordSettings
  } = storePasswordStrength;

  const { resetPasswordToken } = storeAuthorization;

  const { pathname } = useLocation();

  const [fetching, setFetching] = useState<boolean>(false);
  const [pinDialogShown, setPinDialogShown] = useState(false);
  const [pin, setPin] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>(
    `${BASE_URL_FILE_DIR}doc_img/images/logo.png?${Date.now()}`
  );
  const [credentials, setCredentials] = useState<{ username: string; password: string }>({
    username: '',
    password: '',
  });

  const handleLoadLogoError = () => {
    setLogoUrl(`assets/images/logo-loginpage.png?${Date.now()}`);
  };

  const handleSubmit = async (payload: any) => {
    const token = localStorage.getItem('resetToken');

    if (!token) {
      Notification.danger('There was a problem validating your password reset request - The reset password link is invalid. Please try to reset your password again.')
      history.push(URL_LOGIN);
    }

    const data = {
      token,
      password: payload.password
    };

    try {
      const response = await UserProfileActions.resetUserPassword(data);

      if (typeof response !== 'string') {
        const { message: message_1, pin, username } = response;

        Notification.success(message_1);

        setPin(pin);
        setCredentials({ username, password: payload.password });
        setErrorMsg('');
        setPinDialogShown(true);
        localStorage.removeItem('resetToken');
      }
    } catch (error: any) {
      setPin('');
      setPinDialogShown(false);
      setCredentials({ username: '', password: '' });

      let errorMessage = '';

      if (typeof error === 'string' && error !== '') {
        errorMessage = error;
      } else {
        errorMessage = 'An error occurred.';
      }

      setErrorMsg(errorMessage);

      Notification.danger(errorMessage);
    }
  };

  const handleCancel = () => {
    history.push(URL_LOGIN)
  };

  const handleCloseDialogForgotPassword = () => {
    setPinDialogShown(false);

    localStorage.removeItem('resetToken');

    if (credentials.username && credentials.password) {
      setFetching(true);

      return UserProfileActions.checkLogin(credentials)
        .then((response: any) => {
          const { status } = response?.[0] || {};
          if (status === 'OK') {
            UserProfileActions.pinCheck(
              pin,
              history,
              credentials
            );
          } else if (status === 'P') {
            history.push(URL_CHANGE_PASSWORD);
          } else {
            Notification.danger('Invalid credentials');
            history.push(URL_LOGIN);
          }
        })
        .finally(() => setFetching(false));
    } else {
      history.push(URL_LOGIN);
      return Promise.resolve();
    }
  };

  useLayoutEffect(() => {
    if (resetPasswordToken) {
      localStorage.setItem('resetToken', resetPasswordToken);
      history.replace('/user/reset-password/');
    }

    if (!passwordSettings) storePasswordStrength.getPasswordSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordToken]);

  useEffect(() => {
    const token = pathname.split('/user/reset-password/')[1];
    storeAuthorization.setResetPasswordToken(token);

    return storeAuthorization.clearResetPasswordToken;
  }, [pathname]);

  return (
    <div className={`wrapper${fetching ? ' on-ajax' : ''}`}>
      <div className="reset-password-box" id="reset-password-box">
        <div className="reset-password-logo">
          <img
            src={logoUrl}
            onError={handleLoadLogoError}
            alt="Lattice Pro"
          />
        </div>

        {errorMsg ? <p className="reset-password-error">{errorMsg}</p> : null}

        <FormChangePassword
          className="form-reset-password"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />

        {pinDialogShown ? (
          <DialogPinDisplaying
            pin={pin}
            onClose={handleCloseDialogForgotPassword}
          />
        ) : null}
      </div>
    </div>
  );
};

export default observer(ResetPasswordPage);