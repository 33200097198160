import React from 'react';
import { Container } from 'flux/utils';
import { Observer } from 'mobx-react-lite';

import {
  LICENSE_CERTIFICATION,
  LICENSE_CREDENTIAL,
} from 'page/privileges/app-users/FormComponentsProvider';
import DialogSecurityDetails from 'page/components/DialogSecurityDetails';
import { Button } from 'components/button';
import { Grid } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import DialogConfirm from 'components/modal/dialogConfirm';
import Exporter from 'components/project/Exporter';
import { TPagination } from 'components/grid/GridTypes';
import { IconButton } from 'components/button';
import Text from 'components/form/input/Text';
import Form from 'components/form/Form';
import ApplicationUsersDropdown from 'components/project/dropdown/ApplicationUsersDropdown';
import Radio from 'components/form/input/Radio';
import EmployeeInformationActions from 'actions/reports/hr-reports/EmployeeInformationActions';
import Notification from 'components/modal/Notification';
import ApplicationUsersEdit from 'page/privileges/ApplicationUsersEdit';
import { GridControlButton } from 'components/grid';
import LicensesPopup from './LicensesPopup';
import DialogNotificationMessage, {
  FormModel as NotifyMessageType,
} from '../components/DialogNotificationMessage';

import ApplicationUsersPageActions from 'actions/privileges/ApplicationUsersPageActions';
import SecurityPermissionActions from 'actions/SecurityPermissionActions';
import UserSelectionActions from 'actions/project/UserSelectionActions';
import { storeGrid } from 'stores/_mobx/grid';
import { storeUser } from 'stores/_mobx/users/user';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import ApplicationUsersPageStore from 'stores/privileges/ApplicationUsersPageStore';
import UserTypeStore from 'stores/project/UserTypeStore';
import UserSelectionStore from 'stores/project/UserSelectionStore';
import UserProfileStore from 'stores/UserProfileStore';
import { getDateString } from 'utils/DateUtils';
import { getPagination } from 'utils/getPagination';
import AccessUtils from 'utils/AccessUtils';
import { USER_APP as USER_PAGE_ID } from 'constant/pagesId/privileges';
import { EMPLOYEE_INFO as PAGE_ID } from 'constant/pagesId/reports';
import DialogFormResetPassword from 'page/components/DialogFormResetPassword';

const GRID_ID = 'EmployeeInformationGrid';

const LICENSE_OPTIONS = [
  { value: LICENSE_CREDENTIAL, label: 'Credentials' },
  { value: LICENSE_CERTIFICATION, label: 'Certification' },
  { value: '', label: 'Both' },
];
const EXPIRATION_OPTIONS = [
  { value: 'ES', label: 'Expiring Soon' },
  { value: 'E', label: 'Expired' },
  { value: 'A', label: 'All' },
];
const ACTIVE_OPTIONS = [
  { value: 'A', label: 'Active Users' },
  { value: 'I', label: 'Inactive Users' },
  { value: '', label: 'All' },
];

const HAS_CERTIFICATIONS = 'has_certifications';
const HAS_CREDENTIALS = 'has_credentials';

const EXPIRE_MESSAGE =
  '<div>' +
  '<div>Dear #EMPLOYEE_NAME#,</div>' +
  '<div>You are receiving this notification because you have one or more credentials and/or certifications expiring soon. Please take a moment to check the expiration date(s) on your uploaded licenses.</div>' +
  '<div>Thank You</div>' +
  '</div>';

const notifyDefaultValues: NotifyMessageType = {
  subject: 'Credentials/Certifications expiring soon',
  body: EXPIRE_MESSAGE,
};

const defaultFilter: FilterModel = {
  lastName: '',
  firstName: '',
  userType: 0,
  licenses: '',
  expiration: '',
  active: 'A',
};

interface FilterModel {
  lastName: '';
  firstName: '';
  userType: 0;
  licenses: '';
  expiration: '';
  active: 'A';
}

interface PEmployeeInformation {}

class SEmployeeInformation {
  onAjax: boolean = false;
  pagination = getPagination();
  filter: any = {};
  securityPermissionAllowed: string = '';
  privileges: { isTech: boolean; userType: any; userId: number };
  dataSource: Array<any> = [];
  dataSourceCount: number = 0;
  showChangeStatus: boolean = false;
  isActiveStatusVisible: boolean = false;
  showExporter: boolean = false;
  selected: Array<any> = [];
  itemToDelete: number = 0;
  displayEditPage: { refid: number } = null;
  isHrAdminDirty: boolean = false;
  showCertifications: any = null;
  notifyValues: NotifyMessageType | null = null;
  showCredentials: any = null;
}

export class EmployeeInformation extends React.Component<
  PEmployeeInformation,
  SEmployeeInformation
> {
  static getStores() {
    return [ApplicationUsersPageStore];
  }

  static calculateState(prevState: SEmployeeInformation) {
    if (!prevState) {
      const filter = storeGrid.getFilter(GRID_ID, defaultFilter);
      prevState = {
        ...new SEmployeeInformation(),
        filter,
      };
    }
    const isHrAdminDirty =
      ApplicationUsersPageStore.getState().isHrAdminDirty || false;

    return {
      ...prevState,
      isHrAdminDirty,
      dataSource: isHrAdminDirty
        ? ApplicationUsersPageStore.getState().dataSource
        : [],
      dataSourceCount: isHrAdminDirty
        ? ApplicationUsersPageStore.getState().dataSourceCount
        : 0,
    };
  }

  startShownColumns: any = null;

  renderControl = (id: number) => (
    <div className="control">
      {AccessUtils.checkAccess(USER_PAGE_ID.EDIT) && (
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            this.setState({ displayEditPage: { refid: id } });
          }}>
          <i className="bi bi-pencil" />
        </IconButton>
      )}
      {/* Temporary deactivated according to task "feature/LPFE-856"  */}
      {/* {AccessUtils.checkAccess(USER_PAGE_ID.DELETE) && (
          <IconButton onClick={this.showDeleteCodePopup.bind(this, data.refid)}>
            <i className="bi bi-trash" />
          </IconButton>
        )} */}
    </div>
  );

  showPrivileges = (isTech: boolean, userType: any, userId: any) => {
    this.setState({
      privileges: { isTech, userType, userId },
    });
  };

  buildPrivilegesLink = (data: any) => {
    const isTech = data.type === '0';
    const application = isTech ? 'parentsPerm' : 'Userperm';
    const hasPermission =
      UserSelectionStore.isUserTypesLoaded() &&
      AccessUtils.checkAccess(application);

    return hasPermission ? (
      <IconButton
        className="fs-4 text-primary"
        onClick={() => {
          this.showPrivileges(isTech, data.usertype_id, data.refid);
        }}>
        <i className="bi bi-person-gear" />
      </IconButton>
    ) : null;
  };

  showResetPasswordPopup = (user: any, event: any) => {
    event.preventDefault();

    storeUser.setPasswordResetDetails({
      userId: user.refid,
      userName: user.user_name,
    });
  };

  getColumns = () => {
    const { state } = this;

    const columns: Array<{
      name: string;
      title: string;
      className?: string;
      render?: (v: any, row: any) => any;
    }> = [
      { name: 'last_name', title: 'Last Name' },
      { name: 'first_name', title: 'First Name' },
      { name: 'user_name', title: 'Username' },
      { name: 'usertypename', title: 'User Type' },
      { name: 'facility', title: 'Facility' },
      { name: 'birthdate', title: 'Date Of Birth' },
      { name: 'mobile', title: 'Mobile Phone' },
    ];
    if (UserProfileStore.haveAccess('usrperm_save')) {
      columns.push({
        name: 'privilege',
        title: 'Privilege',
        className: 'text-center',
        render: (v: any, row: any) =>
          Number(row.usertype_id) === 8 ? null : this.buildPrivilegesLink(row),
      });
    }
    const { securityPermissionAllowed, filter } = state;
    const userTypeToggler =
      securityPermissionAllowed && filter ? filter['userTypeToggler'] : '';
    if (
      securityPermissionAllowed &&
      filter &&
      ((securityPermissionAllowed.indexOf('client') >= 0 &&
        (userTypeToggler === 'A' || userTypeToggler === '')) ||
        (securityPermissionAllowed.indexOf('company') >= 0 &&
          (userTypeToggler === 'B' || userTypeToggler === '')) ||
        (securityPermissionAllowed.indexOf('clinician') >= 0 &&
          userTypeToggler === ''))
    ) {
      columns.push({
        name: 'security',
        title: 'Security',
        render: (v: any, user: any) =>
          user.flagViewPassword ? (
            <IconButton
              onClick={() => {
                storeUser.getSecurityDetails({
                  userType: user.usertype_id,
                  userId: user.UserId,
                });
              }}>
              <i className="icon icon-security" />
            </IconButton>
          ) : null,
      });
    }
    if (AccessUtils.checkAccess(USER_PAGE_ID.PASSWORD_RESET)) {
      columns.push({
        name: 'resetPassword',
        title: 'Login/Password',
        className: 'text-center',
        render: (v, row) => (
          <a
            href="/"
            onClick={(e) => this.showResetPasswordPopup(row, e)}
            className="color-black fw-bold">
            Reset
          </a>
        ),
      });
    }

    columns.push({
      name: HAS_CERTIFICATIONS,
      title: 'Cert',
      render: (has_certifications, showCertifications) =>
        has_certifications ? (
          <IconButton
            onClick={() => {
              this.setState({ showCertifications });
            }}>
            <i className="icon icon-folder" />
          </IconButton>
        ) : null,
    });
    columns.push({
      name: HAS_CREDENTIALS,
      title: 'ID',
      render: (has_credentials, showCredentials) =>
        has_credentials ? (
          <IconButton
            className="fs-4 text-primary"
            onClick={() => {
              this.setState({ showCredentials });
            }}>
            <i className="bi bi-person-gear" />
          </IconButton>
        ) : null,
    });
    columns.push({ name: 'valid_certification', title: 'Cert Expiration' });
    columns.push({ name: 'valid_credential', title: 'ID Expiration' });
    columns.push({
      name: 'refid',
      title: 'Actions',
      className: 'width-100',
      render: this.renderControl,
    });
    return columns;
  };

  handleChangeFilter = (name: string, value?: string) => {
    const filter = { ...this.state.filter, [name]: value };

    this.setState({ filter });
  };

  handleApplyFilter = () => {
    const pagination = {
      ...this.state.pagination,
      skip: 0,
      page: 1,
    };
    this.setState({ pagination }, this.updateData);
  };

  getFilter() {
    return (
      <Form
        onCollectValues={this.handleChangeFilter}
        model={this.state.filter}
        submit={this.handleApplyFilter}>
        <Text name="lastName" className="col-sm-3" />
        <Text name="firstName" className="col-sm-3" />

        <ApplicationUsersDropdown
          name="userType"
          className="col-sm-3"
          applyFiltering
          userType="B"
        />
        <div />
        <Radio
          options={LICENSE_OPTIONS}
          name="licenses"
          className="col-sm-3 part-inline"
        />
        <Radio
          options={EXPIRATION_OPTIONS}
          name="expiration"
          className="col-sm-3 part-inline"
        />
        <Radio
          options={ACTIVE_OPTIONS}
          name="active"
          label=" "
          className="col-sm-3 part-inline"
        />
      </Form>
    );
  }

  getStartShownColumns() {
    if (this.startShownColumns === null) {
      const showThis: any = {
        last_name: true,
        first_name: true,
        usertypename: true,
        birthdate: true,
        ssn: true,
        has_credentials: true,
        has_certifications: true,
        valid_certification: true,
        valid_credential: true,
        control: true,
      };
      this.startShownColumns = {};
      this.getColumns().forEach((v) => {
        this.startShownColumns[v.name] = !!showThis[v.name];
      });
      this.startShownColumns.security = false;
    }
    return this.startShownColumns;
  }

  handleCloseNotifyDialog = () => {
    this.setState({ notifyValues: null });
  };

  performNotifyMessage = (model: NotifyMessageType) =>
    EmployeeInformationActions.notifyExpired(
      this.state.selected,
      model.subject,
      model.body
    ).then((v) => {
      this.handleCloseNotifyDialog();
      if (v.success === 1) {
        Notification.success('Employees was notified');
      } else {
        Notification.warning("Can't notify employee");
      }
    });

  getExportColumns() {
    const columns = this.getColumns();
    const forbidden = [
      HAS_CREDENTIALS,
      HAS_CERTIFICATIONS,
      'control',
      'loginDetails',
      'resetPassword',
      'privilege',
    ];
    return columns.filter((v) => forbidden.indexOf(v.title) === -1);
  }

  getPdfExporterFilters() {
    const filter = { ...this.state.filter };
    filter.toString = () => {
      const out = [];
      if (filter.firstName !== '') {
        out.push('First name: ' + filter.firstName);
      }
      if (filter.lastName !== '') {
        out.push('Last name: ' + filter.lastName);
      }
      if (filter.userType > 0) {
        const option = UserTypeStore.findUserType(filter.userType);
        if (option) {
          out.push('User Type: ' + option.label);
        }
      }
      if (filter.licenses !== '') {
        out.push('Document Type: ' + filter.licenses);
      }

      if (filter.expiration !== '') {
        if (filter.expiration === 0) {
          out.push(`Document Expire Today (${getDateString()})`);
        } else {
          out.push('Document Expire in: ' + filter.expiration + ' days');
        }
      }
      return out.join('; ');
    };
    return filter;
  }

  showActivations() {
    return false;
  }

  getControlPanel() {
    return (
      <>
        <GridControlButton
          title="Export"
          onClick={() => {
            this.setState({ showExporter: true });
          }}
        />
        <GridControlButton
          title="Notify"
          onClick={() => {
            if (this.state.selected.length) {
              this.setState({ notifyValues: notifyDefaultValues });
            } else {
              Notification.warning('Please select at least one employee');
            }
          }}
        />
      </>
    );
  }

  handleClickExport = () => {
    this.setState({ showExporter: true });
  };

  changeStatusDialog = () => {
    this.setState({ showChangeStatus: true });
  };

  resetFilter = () => {
    const pagination = {
      ...this.state.pagination,
      skip: 0,
      page: 1,
    };
    this.setState({ pagination, filter: defaultFilter }, this.updateData);
  };

  onUpdateSelection = (selected: any) => {
    this.setState({ selected });
  };

  onPaginationChange = (pagination: TPagination) => {
    this.setState({ pagination }, this.updateData);
  };

  handleCloseDialogDelete = () => {
    this.setState({ itemToDelete: 0 });
  };

  handleApproveDelete = () => {
    this.setState({ itemToDelete: 0, onAjax: true });

    ApplicationUsersPageActions.deleteUser(this.state.itemToDelete).then(() => {
      Notification.success('Item was deleted');
      this.updateData();
    });
  };

  handleCloseDialogToggleStatus = () => {
    this.setState({ showChangeStatus: false });
  };

  handleApproveToggleUserStatus = () => {
    const { selected, isActiveStatusVisible } = this.state;

    ApplicationUsersPageActions.changeActiveStatus(
      selected,
      isActiveStatusVisible ? 'Active' : 'Inactive'
    ).then(() => {
      this.setState({ selected: [], showChangeStatus: false }, this.updateData);
      (this.refs.grid as Grid).clearSelection();
      Notification.success(
        `Users were ${
          isActiveStatusVisible ? 'deactivated' : 'activated'
        } successfully!`
      );
    });
  };

  updateData = () => {
    const { pagination, filter, isHrAdminDirty } = this.state;
    if (isHrAdminDirty) {
      this.setState(
        {
          onAjax: true,
          isActiveStatusVisible: filter.active === 'A',
        },
        () => {
          EmployeeInformationActions.loadOverview(filter, pagination).then(
            this.updateDataCallback
          );
        }
      );
    }
  };

  updateDataCallback = () => {
    const grid = this.refs.grid;

    this.setState({
      onAjax: false,
      selected: [],
    });

    if (grid) {
      // @ts-ignore
      grid.clearSelection();
    }
  };

  componentWillUnmount() {
    storeGrid.saveFilter(GRID_ID, this.state.filter);
  }

  componentDidMount() {
    storePasswordStrength.getPasswordSettings();
    const userId = UserProfileStore.getUserId();
    SecurityPermissionActions.getUserViewPasswordPermission(userId).then(
      (response) => {
        this.setState({ securityPermissionAllowed: response });
        if (UserSelectionStore.isUserTypesLoaded()) {
          this.updateData();
        } else {
          UserSelectionActions.loadUserTypes().then(this.updateData);
        }
      }
    );
  }

  render() {
    const { isActiveStatusVisible, displayEditPage, ...state } = this.state;
    const licenseObject = state.showCertifications || state.showCredentials;
    const showLicenses = !!licenseObject;
    return (
      <div>
        {displayEditPage && (
          <ApplicationUsersEdit
            callback={(v) =>
              this.setState({ displayEditPage: null }, () => {
                if (v) {
                  this.updateData();
                }
              })
            }
            // @ts-ignore
            match={{ params: { id: `${displayEditPage.refid}` } }}
          />
        )}
        {!state.isHrAdminDirty && (
          <div className="forbidden-overlay">
            <div className="forbidden-content">
              Only HR admin can see this page
            </div>
          </div>
        )}
        <div className={displayEditPage !== null ? 'visually-hidden' : ''}>
          <>
            <LayoutSideTitle appId={PAGE_ID.PAGE} title="Employee Information">
              <Button
                variant="warning"
                text="Reset"
                onClick={this.resetFilter}
              />
              <Button text="Apply" onClick={this.handleApplyFilter} />
            </LayoutSideTitle>

            {this.getFilter()}

            <Grid
              ref="grid"
              selectId="refid"
              id={GRID_ID}
              onAjax={this.state.onAjax}
              dataSource={this.state.dataSource}
              dataSourceCount={this.state.dataSourceCount}
              pagination={this.state.pagination}
              gridControlPanel={
                <>
                  {this.getControlPanel()}
                  {AccessUtils.checkAccess(USER_PAGE_ID.INACTIVE) && (
                    <GridControlButton
                      title={isActiveStatusVisible ? 'Deactivate' : 'Activate'}
                      onClick={this.changeStatusDialog}
                      disabled={!this.state.selected?.length}
                    />
                  )}
                  <GridControlButton
                    title="Export"
                    onClick={this.handleClickExport}
                  />
                </>
              }
              columns={this.getColumns()}
              shownColumns={this.getStartShownColumns()}
              onSelectChange={this.onUpdateSelection}
              onPaginationChange={this.onPaginationChange}
            />
            {this.state.itemToDelete ? (
              <DialogConfirm
                onCancel={this.handleCloseDialogDelete}
                onApprove={this.handleApproveDelete}>
                Are you sure you want to delete?
              </DialogConfirm>
            ) : null}

            <Observer>
              {() =>
                storeUser.passwordResetDetails && (
                  <DialogFormResetPassword
                    {...storePasswordStrength.passwordOptions}
                    onClose={storeUser.clearPasswordResetDetails}
                  />
                )
              }
            </Observer>

            {this.state.showChangeStatus && (
              <DialogConfirm
                onCancel={this.handleCloseDialogToggleStatus}
                onApprove={this.handleApproveToggleUserStatus}>
                {isActiveStatusVisible
                  ? 'Deactivate the selected person(s)?'
                  : 'Activate the selected person(s)?'}
              </DialogConfirm>
            )}
            <Observer>
              {() =>
                storeUser.securityDetails && (
                  <DialogSecurityDetails
                    details={storeUser.securityDetails}
                    onClose={storeUser.clearSecurityDetails}
                  />
                )
              }
            </Observer>

            {this.state.showExporter && this.refs && this.refs.grid ? (
              <Exporter
                filters={() => this.getPdfExporterFilters()}
                callback={() => this.setState({ showExporter: false })}
                reportName={
                  UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
                  'Application_Users'
                }
                this={this}
              />
            ) : null}
          </>
          {showLicenses ? (
            <LicensesPopup
              userId={licenseObject.refid}
              documentType={
                state.showCertifications !== null
                  ? LICENSE_CERTIFICATION
                  : LICENSE_CREDENTIAL
              }
              userName={[licenseObject.first_name, licenseObject.last_name]
                .filter((v) => v)
                .join(' ')}
              onClose={() => {
                this.setState({
                  showCertifications: null,
                  showCredentials: null,
                });
              }}
            />
          ) : null}

          {this.state.notifyValues && (
            <DialogNotificationMessage
              title="Notify"
              defaultValues={this.state.notifyValues}
              onClose={this.handleCloseNotifyDialog}
              onSubmit={this.performNotifyMessage}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Container.create(EmployeeInformation);
