export const USER_APP = {
  ADD: 'applnusr_add',
  ACTIVE: 'applnusr_active',
  INACTIVE: 'applnusr_inactive',
  PASSWORD_RESET: 'applnusr_password',
  DELETE: 'applnusr_delete',
  EDIT: 'applnusr_edit',
  PAGE: 'UserMaster',
};

export const USER_TYPE = {
  GROUP_SAVE: 'gpperm_save',
  PAGE: 'UsrGpPerm',
};

export const CLIENT = {
  SAVE: 'usrperm_save',
  VIEW_LOG: 'usrperm_permchgelog',
  PAGE: 'Userperm',
};

export const COMPANY = {
  PAGE: 'parentsPerm',
};

export const USER_LOG = {
  PAGE: 'Currentuser',
};

export const LOGIN_LOG = {
  PAGE: 'AllLogin',
};

export const AUDIT_LOG = {
  PAGE: 'Auditlog',
};
