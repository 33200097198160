import { makeObservable, observable, action } from 'mobx';

import Notification from 'components/modal/Notification';

import { apiRequest } from 'services/RequestService';

interface RadiologistCreatingPayloadType {
  firstName: string;
  middleName: string;
  lastName: string;
  npi: string;
  groupId?: number;
  email: string;
}

interface RadiologistCreatingResponseType {
  iPinVal: string;
  radiologistId: number;
}

interface RadiologistAbbreviatedInfoResponseType {
  data: string;
  email: string;
  fax: string;
  first_name: string;
  home_address: string;
  home_city: string;
  home_state: string;
  home_zipcode: string;
  image: string;
  label: string;
  last_name: string;
  work_phone: string;
}

interface RadiologistOptionType {
  label: string;
  value: number;
  firstName: string;
  lastName: string;
}

export interface RadiologistType
  extends RadiologistCreatingPayloadType,
    RadiologistCreatingResponseType {}

class Radiologist {
  fetching: boolean = false;
  fetchingOptions: boolean = false;
  options: RadiologistOptionType[] = [];

  constructor() {
    makeObservable(this, {
      fetching: observable,
      fetchingOptions: observable,
      options: observable,

      setFetching: action,
      setFetchingOptions: action,
      setOptions: action,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setFetchingOptions(fetching: boolean) {
    this.fetchingOptions = fetching;
  }

  setOptions(options: RadiologistOptionType[]) {
    this.options = options;
  }

  async createRadiologist(
    payload: RadiologistCreatingPayloadType
  ): Promise<null | RadiologistType> {
    const data = {
      firstName: payload.firstName,
      middleName: payload.middleName,
      lastName: payload.lastName,
      radiologistNpi: payload.npi,
      radiologyGroupId: payload.groupId || null,
      email: payload.email,
    };

    this.setFetching(true);

    try {
      const response = await apiRequest<'SE' | 'C' | RadiologistCreatingResponseType>(
        {
          url: 'usermaster.Radiologist.addRadiologistByPopup',
          data,
        }
      );

      if (response === 'C') {
        Notification.warning('This email is probably in use!');
        return;
      }

      if (response !== 'SE') {
        Notification.success(`Radiologist created, PIN: ${response.iPinVal}`, {
          autoClose: 10000,
        });
        return {
          ...response,
          ...payload,
        };
      } else {
        throw Error('');
      }
    } catch (e: any) {
      Notification.danger('Radiologist create error!');
      return null;
    }
  }

  async getRadiologistsAbbreviatedInfo() {
    this.setFetchingOptions(true);
    try {
      const response = await apiRequest<
        RadiologistAbbreviatedInfoResponseType[]
      >({
        url: 'generalmaster.RadiologyGroup.LoadRadiologistList',
      });

      const options = response
        .filter((el) => {
          const label = el.label.trim();

          return Boolean(label);
        })
        .map((el) => ({
          label: el.label,
          value: Number(el.data),
          firstName: el.first_name,
          lastName: el.last_name,
        }));

      this.setOptions(options);
    } catch (e: any) {
      this.setOptions([]);
    } finally {
      this.setFetchingOptions(false);
    }
  }
}

export const storeRadiologist = new Radiologist();
