import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validation = yupResolver(
  yup.object().shape({
    secQues1: yup
      .number()
      .nullable()
      .positive()
      .required("Please choose a question."),
    secQues2: yup
      .number()
      .nullable()
      .positive()
      .required("Please choose a question."),
    answer1: yup.string().required("Can't be empty."),
    answer2: yup.string().required("Can't be empty."),
  })
);

export default validation;