import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import DialogFormResetPassword from 'page/components/DialogFormResetPassword';
import DialogSecurityDetails from 'page/components/DialogSecurityDetails';
import Exporter from 'components/project/ExporterNew';
import { Button, IconButton } from 'components/button';
import { Grid, GridControlButton } from 'components/grid';
import { LayoutSideTitle } from 'components/layout';
import DialogConfirm from 'components/modal/dialogConfirm';
import SelectFacade from 'components/grid/columns/SelectFacade';
import { SpinnerFixed } from 'components/spinner';
import Filter from './components/Filter';
import PrivilegesForm from './components/PrivilegesForm';

import UserProfileStore from 'stores/UserProfileStore';
import { storeUser, UserType, FilterType } from 'stores/_mobx/users/user';
import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';
import { USER_APP as PAGE_ID } from 'constant/pagesId/privileges';
import {
  URL_APPLICATION_USER,
  URL_APPLICATION_USER_ADD,
} from 'constant/path/privileges';

interface PrivilegesType {
  userType: number;
  userId: number;
  isCompanyUser: boolean;
}

const ApplicationUsersListPage = () => {
  const gridRef = useRef<Grid>();

  const {
    fetching,
    fetchingPermissions,
    fetchingSecurity,
    usersList,
    usersTotal,
    permission,
    idsForChangeStatus,
    filter,
    securityDetails,
    passwordResetDetails,
    page: { pagination, setPagination, setPaginationToStart },
  } = storeUser;

  const [isFilterVisible, toggleFilter] = useState<boolean>(false);

  const [isExporterVisible, toggleExporter] = useState<boolean>(false);

  const [selected, setSelected] = useState<number[]>([]);

  const [privileges, setPrivileges] = useState<null | PrivilegesType>(null);

  const columns = useMemo(() => {
    const columns = [
      { name: 'last_name', title: 'Last Name' },
      { name: 'first_name', title: 'First Name' },
      { name: 'user_name', title: 'Username' },
      { name: 'email', title: 'Email' },
      { name: 'usertypename', title: 'User Type' },
      { name: 'facility', title: 'Facility' },
      { name: 'birthdate', title: 'Date Of Birth', className: 'text-nowrap' },
      { name: 'mobile', title: 'Mobile Phone' },
      permission.privileges
        ? {
            name: 'privilege',
            title: 'Privilege',
            className: 'text-center',
            render: (value: undefined, user: UserType) => {
              const isSuperAdmin = user.usertype_id === 8;

              const isCompanyUser = user.type === 0;

              const hasPermission = isCompanyUser
                ? permission.privilegesCompanyUser
                : permission.privilegesClientUser;

              return hasPermission && !isSuperAdmin ? (
                <IconButton
                  className="fs-4 text-primary"
                  onClick={() => {
                    setPrivileges({
                      isCompanyUser,
                      userType: user.usertype_id,
                      userId: user.refid,
                    });
                  }}>
                  <i className="bi bi-person-gear" />
                </IconButton>
              ) : null;
            },
          }
        : null,

      permission.viewCredentials
        ? {
            name: 'security',
            title: 'Security',
            render: (value: undefined, user: UserType) =>
              user.flagViewPassword && (
                <IconButton
                  onClick={() => {
                    storeUser.getSecurityDetails({
                      userType: user.usertype_id,
                      userId: user.UserId,
                    });
                  }}>
                  <i className="icon icon-security" />
                </IconButton>
              ),
          }
        : null,

      permission.resetPassword
        ? {
            name: 'resetPassword',
            title: 'Password',
            className: 'text-center',
            render: (value: undefined, { refid, user_name }: UserType) => (
              <a
                href="/"
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  storeUser.setPasswordResetDetails({
                    userId: refid,
                    userName: user_name,
                  });
                }}
                className="color-black fw-bold">
                Reset
              </a>
            ),
          }
        : null,

      {
        name: 'control',
        title: 'Actions',
        className: 'width-50',
        render: (value: undefined, { refid }: UserType) => (
          <div className="control">
            {permission.editUser && (
              <Link
                className="bi bi-pencil"
                to={`${URL_APPLICATION_USER}/edit/${refid}`}
              />
            )}
            {/* Temporary deactivated according to task "feature/LPFE-856"  */}
            {/* {AccessUtils.checkAccess(PAGE_ID.DELETE) && (
          <IconButton onClick={this.showDeleteCodePopup.bind(this, data.refid)}>
            <i className="bi bi-trash" />
          </IconButton>
        )} */}
          </div>
        ),
      },
    ];

    return columns.filter((column) => Boolean(column));
  }, [
    permission.privileges,
    permission.viewCredentials,
    permission.resetPassword,
    permission.editUser,
    permission.privilegesClientUser,
    permission.privilegesCompanyUser,
  ]);

  const exportColumns = useMemo(() => {
    const exportColumns = columns.filter((column) => column.name !== 'resetPassword');

    return exportColumns.filter((column) => Boolean(column));
  }, [columns]);

  const handleToggleFilter = () => {
    toggleFilter((state) => !state);
  };

  const handleToggleExport = () => {
    toggleExporter((state) => !state);
  };

  const fetchData = () => {
    if (SelectFacade.length) {
      gridRef.current.clearSelection();
    }
    storeUser.getUsersListMain();
  };

  const handleApproveChangeStatus = () => {
    storeUser.changeUserStatusMain().then((isSucceed) => {
      if (isSucceed) {
        fetchData();
      }
    });
  };

  const handleClosePrivileges = () => {
    setPrivileges(null);
  };

  const handleChangeFilter = (filter: FilterType) => {
    storeUser.setFilter(filter);

    setPaginationToStart();
  };

  useEffect(() => {
    storeUser.setDefaultFilter();
    storeUser.getPermissionUserType();
    storePasswordStrength.getPasswordSettings();

    return storeUser.clearPermissionUserType;
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return privileges ? (
    <PrivilegesForm {...privileges} onClose={handleClosePrivileges} />
  ) : (
    <>
      <LayoutSideTitle appId={PAGE_ID.PAGE} title="Application Users">
        <Button
          variant="default"
          text={isFilterVisible ? 'Hide filter' : 'Show filter'}
          onClick={handleToggleFilter}
        />
      </LayoutSideTitle>

      <Filter isVisible={isFilterVisible} onSubmit={handleChangeFilter} />

      <Grid
        selectId="refid"
        id="application_users_grid"
        ref={gridRef}
        columns={columns}
        dataSource={usersList}
        dataSourceCount={usersTotal}
        pagination={pagination}
        onSelectChange={setSelected}
        onPaginationChange={setPagination}
        gridControlPanel={
          <>
            {permission.addUser && (
              <GridControlButton title="Add" url={URL_APPLICATION_USER_ADD} />
            )}
            {permission.toggleState && (
              <GridControlButton
                title={filter.status === 'A' ? 'Deactivate' : 'Activate'}
                onClick={() => storeUser.setIdsForChangeStatus(selected)}
                disabled={!selected.length}
              />
            )}
            <GridControlButton title="Export" onClick={handleToggleExport} />
          </>
        }
      />

      {idsForChangeStatus.length ? (
        <DialogConfirm
          onCancel={storeUser.clearIdsForChangeStatus}
          onApprove={handleApproveChangeStatus}>
          {filter.status === 'A'
            ? `Deactivate selected person${selected.length > 1 ? 's' : ''}?`
            : `Activate selected person${selected.length > 1 ? 's' : ''}?`}
        </DialogConfirm>
      ) : null}

      {securityDetails && (
        <DialogSecurityDetails
          details={securityDetails}
          onClose={storeUser.clearSecurityDetails}
        />
      )}

      {passwordResetDetails && (
        <DialogFormResetPassword
          {...storePasswordStrength.passwordOptions}
          onClose={storeUser.clearPasswordResetDetails}
        />
      )}

      {isExporterVisible && (
        <Exporter
          reportName={
            UserProfileStore.findAppNameByAppId(PAGE_ID.PAGE) ||
            'Application_Users'
          }
          columns={exportColumns}
          gridRef={gridRef}
          dataSource={usersList}
          filter={filter}
          onClose={handleToggleExport}
        />
      )}

      {(fetching || fetchingPermissions || fetchingSecurity) && (
        <SpinnerFixed />
      )}
    </>
  );
};

export default observer(ApplicationUsersListPage);
