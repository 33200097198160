import { observable, action, makeObservable } from 'mobx';

import { apiRequest } from 'services/RequestService';
import { LOGGED_IN } from 'stores/UserProfileStore';
import { SESSION_STORAGE_KEY_FOR_APP_OPTIONS } from 'constant/config';

export type TokenResponseType = {
  refresh_token: string;
  token: string;
};

interface SessionStorageOptionsType {
  userID: string;
  userType: string;
}

class Authorization {
  fetching: boolean = false;
  token: string = '';
  resetPasswordToken: string = '';

  constructor() {
    makeObservable(this, {
      fetching: observable,
      token: observable,
      resetPasswordToken: observable,

      setFetching: action,
      setToken: action,
      setResetPasswordToken: action,
      clearToken: action.bound,
      clearResetPasswordToken: action.bound,
    });
  }

  setFetching(fetching: boolean) {
    this.fetching = fetching;
  }

  setToken(token: string) {
    this.token = token;
  }

  clearToken() {
    this.token = '';
  }

  setResetPasswordToken(token: string) {
    this.resetPasswordToken = token;
  }

  clearResetPasswordToken() {
    this.resetPasswordToken = '';
  }

  setTokensToLocalStorage(tokens: TokenResponseType) {
    const props = JSON.stringify(tokens);

    localStorage.setItem('LP', props);
  }

  getAccessTokenFromLocalStorage() {
    const props = localStorage.getItem('LP');

    const { token } = props ? JSON.parse(props) : { token: '' };

    return token;
  }

  getRefreshTokenFromLocalStorage() {
    const props = localStorage.getItem('LP');

    const { refresh_token } = props ? JSON.parse(props) : { refresh_token: '' };

    return refresh_token;
  }

  clearTokensInLocalStorage() {
    localStorage.removeItem('LP');
  }

  setSessionStorageOptions(user: SessionStorageOptionsType) {
    const options = JSON.stringify({ user });
    sessionStorage.setItem(SESSION_STORAGE_KEY_FOR_APP_OPTIONS, options);
  }

  clearSessionStorageOptions() {
    sessionStorage.removeItem(SESSION_STORAGE_KEY_FOR_APP_OPTIONS);
  }

  clearAuthStore = () => {
    window.localStorage.removeItem(LOGGED_IN);
    this.clearSessionStorageOptions();
    this.clearToken();
    this.clearTokensInLocalStorage();
  };

  async login(data: { username: string; password: string }) {
    this.setFetching(true);

    try {
      const { token, refresh_token } = await apiRequest<TokenResponseType>({
        url: 'authentication_token',
        legacy: false,
        method: 'POST',
        data: {
          ...data,
        },
      });
      this.setToken(token);
      this.setTokensToLocalStorage({ token, refresh_token });
    } catch (e: any) {
      this.setToken('');
    } finally {
      this.setFetching(false);
    }
  }

  async logout() {
    try {
      await apiRequest({
        url: 'logout',
        method: 'GET',
        legacy: false,
      });
      this.clearAuthStore();
      return true;
    } catch (e: unknown) {
      return false;
    }
  }

  getParamsForHeader() {
    const lpOptions = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEY_FOR_APP_OPTIONS)
    );

    const currentUserData: SessionStorageOptionsType | null = lpOptions?.user;

    return currentUserData
      ? {
          'User-ID': currentUserData.userID,
          'User-Type': currentUserData.userType,
        }
      : {};
  }
}

export const storeAuthorization = new Authorization();
