import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';
import { validationResetPassword } from './validation';

import { storePasswordStrength } from 'stores/_mobx/systemSetup/masterSetting/passwordStrength';

const defaultValues: FormModel = {
  password: '',
  repeatPassword: '',
};

export interface FormModel {
  password: string;
  repeatPassword: string;
}

interface PropsType {
  className?: string;
  onCancel?: () => void;
  onSubmit: (dta: FormModel) => Promise<any>;
}

const FormChangePassword = ({
  className = 'line-form',
  onCancel,
  onSubmit,
}: PropsType) => {
  const { fetching, passwordOptions } = storePasswordStrength;

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues,
    resolver: validationResetPassword(passwordOptions),
  });

  const handleSubmitChangePassword = handleSubmit((data: FormModel) =>
    onSubmit(data).then(() => {
      reset(defaultValues);
    })
  );

  const wrapperCn = clsx(className, { 'on-ajax': fetching });

  useEffect(() => {
    if (!fetching) storePasswordStrength.getPasswordSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className={wrapperCn} onSubmit={handleSubmitChangePassword}>
      <Input
        name="password"
        type="password"
        label="New Password"
        required
        control={control}
      />
      <Input
        name="repeatPassword"
        type="password"
        label="Confirm Password"
        required
        control={control}
      />
      <ControlsLayout alignX="between">
        {onCancel && (
          <Button
            text="Cancel"
            variant="danger"
            disabled={isSubmitting || fetching}
            onClick={onCancel}
          />
        )}
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty || fetching}
        />
      </ControlsLayout>
    </form>
  );
};

export default observer(FormChangePassword);
