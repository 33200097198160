import { forwardRef, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller, Control } from 'react-hook-form';

import UserTypeActions from 'actions/project/UserTypeActions';
import UserSelectionActions from 'actions/project/UserSelectionActions';
import UserTypeStore, { UserTypeItem } from 'stores/project/UserTypeStore';
import AbstractDropdown, {
  PAbstractDropdown,
  SAbstractDropdown,
  TItem,
} from 'components/project/dropdown/AbstractDropdown';
import { TUserTypeEnum } from 'services/project/UserSelectionService';
import { TUserType } from 'services/project/UserTypeService';
import { storeDropdownUserType } from 'stores/_mobx/dropDown/userType';

import PureSelect, { PureSelectProps } from 'components/form/select/PureSelect';

interface PureDropdownPropsType extends Omit<PureSelectProps, 'options'> {
  type?: '' | 0 | 1;
}

interface PropsType
  extends Omit<
    PureDropdownPropsType,
    'name' | 'value' | 'onChange' | 'onBlur' | 'onFocus' | 'errorMessage'
  > {
  control: Control<any>;
  defaultValue?: string;
  name: string;
}

export interface PApplicationUsersDropdown extends PAbstractDropdown {
  userType?: '' | 'A' | 'B';
  applyFiltering?: boolean;
  options?: Array<TItem>;
  withoutFilter?: boolean;
}

export class SApplicationUsersDropdown extends SAbstractDropdown {}

export default class ApplicationUsersDropdown extends AbstractDropdown<
  PApplicationUsersDropdown,
  SApplicationUsersDropdown
> {
  getStore() {
    return UserTypeStore;
  }

  loadOptions() {
    let promise: Promise<Array<TUserType>> = Promise.resolve([]);
    if (this.props.options) {
      UserSelectionActions.loadUserTypes();
    } else {
      if (this.props.withoutFilter) {
        promise = UserTypeActions.loadUserTypeList();
      } else {
        promise = UserTypeActions.loadUserTypeList(this.props.userType);
      }
    }
    return AbstractDropdown.adaptPromise(promise);
  }

  getOptions() {
    return AbstractDropdown.adaptArr(
      this.props.options ||
        UserTypeStore.getState().userTypeList.filter((option: UserTypeItem) =>
          ApplicationUsersDropdown._filter(
            option,
            this.props.applyFiltering,
            this.props.userType
          )
        )
    );
  }

  static _filter(
    option: UserTypeItem,
    applyFiltering: boolean,
    userType: TUserTypeEnum
  ) {
    if (option.value === 2 || option.value === 7) {
      //ignore physicians (value === 2) and patients (value === 7)
      return false;
    } else if (applyFiltering && userType) {
      //A - client user, B - system user, for more info look into Mv_UserType.php::loadUserTypeByType
      return (userType === 'A' ? 1 : 0) === Number(option.type);
    }
    return true;
  }

  getAttributes() {
    const out = super.getAttributes();
    const render = {
      optionRenderer: (option: { label: string; category: string }) => {
        if (option.category) {
          return (
            <div>
              <div>{option.label}</div>
              <div className="app-user-desc">{option.category}</div>
            </div>
          );
        }
        return <div>{option.label}</div>;
      },
    };
    if (!out) {
      return render;
    }
    return Object.assign({}, out, render);
  }
}

export const PureDropDownApplicationUserType = observer(
  forwardRef(({ type = '', ...props }: PureDropdownPropsType, ref: any) => {
    const {
      fetchingOptionsUserType,
      optionsUserType,
      optionsClientUserType,
      optionsCompanyUserType,
    } = storeDropdownUserType;

    const options = useMemo(() => {
      if (type === '') return optionsUserType;

      return type === 0 ? optionsCompanyUserType : optionsClientUserType;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, optionsUserType]);

    useEffect(() => {
      if (!optionsUserType.length) storeDropdownUserType.getOptionsUserType();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <PureSelect
        {...props}
        ref={ref}
        options={options}
        isLoading={fetchingOptionsUserType}
      />
    );
  })
);

export const DropDownApplicationUserType = ({
  name,
  control,
  ...rest
}: PropsType) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => {
      const errorMessage = error?.message || '';

      return (
        <PureDropDownApplicationUserType
          errorMessage={errorMessage}
          {...field}
          {...rest}
        />
      );
    }}
  />
);
