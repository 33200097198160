import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Button } from 'components/button';
import { ControlsLayout } from 'components/layout';

import { storeTermsAndConditions } from 'stores/_mobx/systemSetup/masterSetting/termsAndConditions';
import UserProfileActions from 'actions/UserProfileActions';
import UserProfileStore from 'stores/UserProfileStore';

import { URL_CHANGE_PASSWORD, URL_LOGOUT, URL_SECURITY_QUESTIONS } from 'constant/path/auth';

interface PropsType extends RouteComponentProps {}

const LicenseAgreementPage = ({ history }: PropsType) => {
  const { fetching, terms } = storeTermsAndConditions;

  const handleAcceptLicense = () => {
    const user = UserProfileStore.getUser();

    if (user && user.refid) {
      const userId = Number(user.refid);

      return UserProfileActions.updateTermsAndConditionsAccepted(userId)
        .then((response) => {
          if (response === 'S') {
              const isSecurityQuestionFirstSet = typeof user.sec_ques1 === 'string' && Boolean(user.sec_ques1.trim());
              const isSecurityQuestionSecondSet = typeof user.sec_ques2 === 'string' && Boolean(user.sec_ques2.trim());
              const isSecurityQuestionsSet = isSecurityQuestionFirstSet && isSecurityQuestionSecondSet;

              if (user.view_status !== 'N') {
                history.push(URL_CHANGE_PASSWORD);
              } else if (!isSecurityQuestionsSet) {
                history.push(URL_SECURITY_QUESTIONS);
              } else {
                history.push('/');
              }
          }
        });
    } else {
      history.push(URL_LOGOUT);
    }
  };

  useEffect(() => {
    storeTermsAndConditions.getTerms();
  }, []);

  return (
    <div className={`container${fetching ? ' on-ajax' : ''}`}>
      <div className="wrapper">
        <h2 className="text-center text-danger my-4">Terms and Conditions</h2>

        <div
          className={`mb-3${terms ? '' : ' flex-grow-1'}`}
          dangerouslySetInnerHTML={{ __html: terms }}
        />

        <ControlsLayout alignX="between" className="my-5">
          <Link to={URL_LOGOUT} className="btn btn-danger">
            Disagree
          </Link>
          <Button text="Agree" onClick={handleAcceptLicense} />
        </ControlsLayout>
      </div>
    </div>
  );
};

export default observer(LicenseAgreementPage);
