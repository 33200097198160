import { history } from 'App';

import { storeService } from 'stores/_mobx/service';
import { storeAuthorization } from 'stores/_mobx/auth';
import { prepareSequence } from 'utils/requestHelpers';
import { URL_LOGIN, URL_LOGOUT } from 'constant/path/auth';
import { backend } from 'constant/config';
import { BACKEND_ROOT } from 'constant/config';
import { ServerFormValidationType } from 'types';

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type ContentType = 'json' | 'ld';

interface ViolationType<T extends {}> {
  code: string;
  message: string;
  propertyPath: keyof T;
}

export interface ErrorType<T extends {}> {
  detail: string;
  type: string;
  title: string;
  violations?: ViolationType<T>[];
}

export interface JsonLdResponseType<T> {
  items: T[];
  totalItems: number;
}

interface RequestParams {
  url: string;
  data?: any;
  method?: Method;
  legacy?: boolean;
  signal?: AbortSignal;
  prefixDisabled?: boolean;
  contentType?: ContentType;
}

const streamContentType = ['text/csv'];

const availableHeaders: Record<ContentType, Record<string, string>> = {
  json: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  ld: {
    accept: 'application/ld+json',
    'Content-Type': 'application/ld+json',
  },
};

const refreshTokenService = async (refreshToken: string) => {
  try {
    const headers = {
      accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const body = JSON.stringify({ refresh_token: refreshToken });

    const response = await fetch(`${BACKEND_ROOT}/refresh_token`, {
      method: 'POST',
      headers,
      body,
    });

    const { token, refresh_token } = await response.json();

    storeAuthorization.setToken(token);
    storeAuthorization.setTokensToLocalStorage({ token, refresh_token });
    return token;
  } catch (e: unknown) {
    storeAuthorization.clearToken();
    storeAuthorization.clearTokensInLocalStorage();
    return '';
  }
};

let pendingRequestRefreshToken: Promise<string> | null = null;

export const apiRequest = async <T>({
  url,
  data,
  method = 'POST',
  legacy = true,
  prefixDisabled = false,
  signal,
  contentType = 'json',
}: RequestParams): Promise<T> => {
  const searchParams =
    method === 'GET' && data
      ? new URLSearchParamsSpecific(data).toString()
      : '';

  const prefix = legacy ? backend : BACKEND_ROOT;

  const endpoint = prefixDisabled ? url : `${prefix}/${url}${searchParams}`;

  const dataIsPresented =
    method !== 'GET' && data !== undefined && data !== null;

  const payload = legacy ? prepareSequence(data) : data;

  const headerParams = storeAuthorization.getParamsForHeader();

  const options = {
    method,
    signal,
    headers: {
      ...availableHeaders[contentType],
      ...(method === 'PATCH'
        ? { 'Content-Type': 'application/merge-patch+json' }
        : null),
      ...(storeAuthorization.getAccessTokenFromLocalStorage()
        ? { Authorization: `Bearer ${storeAuthorization.getAccessTokenFromLocalStorage()}` }
        : null),
      ...headerParams,
    },
    body: dataIsPresented ? JSON.stringify(payload) : undefined,
  };

  return fetch(endpoint, options)
    .then((response) => {
      storeService.checkCodeVersion(response.headers);

      return response.ok
        ? response
        : response.json().then((error) => Promise.reject(error));
    })
    .then((response) => {
      const responseContentType = response.headers.get('content-type');

      if (response.status === 204) return Promise.resolve();

      const isFileContent = streamContentType.some((type) =>
        responseContentType?.startsWith(type)
      );

      if (isFileContent) {
        return response.text();
      }

      return contentType === 'ld' ? ldJsonParser(response) : response.json();
    })
    .catch((e: any) => {
      const { pathname } = window.location;
      const isLoginPage =
        pathname.startsWith(URL_LOGOUT) || pathname.startsWith(URL_LOGIN);

      if (e.code === 401 && e.message === 'Expired JWT Token') {
        const refreshToken =
          storeAuthorization.getRefreshTokenFromLocalStorage();

        if (!refreshToken) return Promise.reject(e);

        if (!pendingRequestRefreshToken) {
          pendingRequestRefreshToken = refreshTokenService(
            refreshToken
          ).finally(() => {
            pendingRequestRefreshToken = null;
          });
        }

        return pendingRequestRefreshToken.then((accessToken) => {
          if (accessToken) {
            return apiRequest({
              url,
              data,
              method,
              legacy,
              prefixDisabled,
              signal,
              contentType,
            });
          }
          const queryParams = isLoginPage
            ? ''
            : `?backUrl=${encodeURIComponent(pathname)}`;
          storeAuthorization.clearAuthStore();
          history.push(`${URL_LOGOUT}${queryParams}`);
        });
      } else if ((e.status === 401 || e === 'SE') && !isLoginPage) {
        const url = encodeURIComponent(pathname);
        storeAuthorization.clearSessionStorageOptions();
        history.push(`${URL_LOGOUT}?backUrl=${url}`);
      } else if (e.code === 429 && !storeService.isRateLimitReachedOut) {
        storeService.setRateLimit(true);
      }
      if (e.name === 'AbortError') {
        return Promise.reject('');
      }
      return Promise.reject(e);
    });
};

export const apiFileRequest = async <T>({
  url,
  data,
  method = 'POST',
  signal,
}: Omit<
  RequestParams,
  'prefixDisable' | 'legacy' | 'purePayload'
>): Promise<T> => {
  const endpoint = `${BACKEND_ROOT}/services/${url}`;

  const headerParams = storeAuthorization.getParamsForHeader();

  const options = {
    method,
    signal,
    headers: {
      accept: 'text/html',
      ...(storeAuthorization.getAccessTokenFromLocalStorage()
        ? { Authorization: `Bearer ${storeAuthorization.getAccessTokenFromLocalStorage()}` }
        : null),
      ...headerParams,
    },
    body: data,
  };

  return fetch(endpoint, options)
    .then((response) => {
      storeService.checkCodeVersion(response.headers);
      return response.ok ? response : Promise.reject(response);
    })
    .then((response) => response.text())
    .catch((e) => {
      const { pathname } = window.location;
      const isLoginPage =
        pathname.startsWith(URL_LOGOUT) || pathname.startsWith(URL_LOGIN);

      if (e.status === 401) {
        const refreshToken =
          storeAuthorization.getRefreshTokenFromLocalStorage();
        if (!refreshToken) return Promise.reject(e);

        if (!pendingRequestRefreshToken) {
          pendingRequestRefreshToken = refreshTokenService(
            refreshToken
          ).finally(() => {
            pendingRequestRefreshToken = null;
          });
        }

        return pendingRequestRefreshToken.then((accessToken) => {
          if (accessToken) {
            return apiFileRequest({
              url,
              data,
              method,
              signal,
            });
          }
          const queryParams = isLoginPage
            ? ''
            : `?backUrl=${encodeURIComponent(pathname)}`;
          storeAuthorization.clearAuthStore();
          history.push(`${URL_LOGOUT}${queryParams}`);
        });
      } else if (e.status === 429 && !storeService.isRateLimitReachedOut) {
        storeService.setRateLimit(true);
      }

      if (e.name === 'AbortError') {
        return Promise.reject('');
      }
      return e.json().then((error: any) => Promise.reject(error));
    });
};

function ldJsonParser(response: Response) {
  return response.text().then((plainText) => {
    const parsedData: Record<string, any> = JSON.parse(
      plainText,
      (key: string, value: any) => {
        if (key.startsWith('@') || key === 'hydra:view') return undefined;
        return value;
      }
    );

    const formattedData: Record<string, any> = {};

    for (let key in parsedData) {
      if (key === 'hydra:member') {
        formattedData.items = parsedData[key];
      } else if (key === 'hydra:totalItems') {
        formattedData.totalItems = parsedData[key];
      } else {
        formattedData[key] = parsedData[key];
      }
    }

    return formattedData;
  });
}

type SearchParamsObjectType = Record<
  string,
  string | number | (string | number)[]
>;

export class URLSearchParamsSpecific {
  _incomeParams: SearchParamsObjectType = {};

  /**
   * Create a specific object with searchParams
   * @param {(SearchParamsObjectType)} props - Object with values need to be converted to search params
   */
  constructor(props: SearchParamsObjectType) {
    if (typeof props === 'object' && !Array.isArray(props) && props !== null) {
      let flattedParams: Record<string, any> = {};

      traverseAndFlatten(props, flattedParams);

      for (const key in flattedParams) {
        const searchValue = flattedParams[key];
        if (Array.isArray(searchValue)) {
          const formattedValue = searchValue.filter(Boolean);

          if (formattedValue.length) {
            this._incomeParams[key] = formattedValue;
          }
        } else if (searchValue) {
          this._incomeParams[key] = searchValue;
        }
      }
    }
  }

  /**
   * Get an object with values for search and convert it to string
   * @returns - string of search params like this page=1&modality[]=1&modality[]=2&patient.firstName=John
   */
  toString() {
    const params = this._incomeParams;

    let paramsCollection: string[] = [];

    if (!Object.keys(params).length) return '';
    if (typeof params === 'object' && !Array.isArray(params)) {
      for (const key in params) {
        const searchValue = params[key];

        let formattedValue = '';

        if (Array.isArray(searchValue)) {
          formattedValue = searchValue
            .map((value) => `${key}[]=${value}`)
            .join('&');
        } else {
          formattedValue = `${key}=${searchValue}`;
        }
        paramsCollection.push(formattedValue);
      }
      const finalSearchParams = `?${paramsCollection.join('&')}`;

      return finalSearchParams;
    }
  }
}

/**
 * Only for new endpoints! Function accept error object, prettier it and return array of errors which is ready to displaying it in forms
 * @param {object} e - object with errors comes from backend
 * @returns {Array<field:string, type:string, message:string> | null} - array of specific objects with errors
 */
export const errorPrettier = <T extends object>(
  e: ErrorType<T>
): ServerFormValidationType<T>[] | null => {
  if (Array.isArray(e.violations) && e.violations.length) {
    return e.violations.map(({ message, propertyPath }) => ({
      field: propertyPath,
      type: 'server',
      message,
    }));
  }

  return null;
};

/**
 * This method does convert nested object to flat one
 * @param currentNode - object of nested object which needs to be flattened
 * @param target - final flattened object
 * @param flattenedKey - final key which is simple string splitted by dot
 */
function traverseAndFlatten(
  currentNode: Record<string, any>,
  target: Record<string, any>,
  flattenedKey?: string
) {
  for (var key in currentNode) {
    if (currentNode.hasOwnProperty(key)) {
      var newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = flattenedKey + '.' + key;
      }

      var value = currentNode[key];
      if (typeof value === 'object' && !Array.isArray(value)) {
        traverseAndFlatten(value, target, newKey);
      } else {
        target[newKey] = value;
      }
    }
  }
}
