import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Notification from 'components/modal/Notification';
import Title from 'components/project/common/title';
import DialogPinDisplaying from 'components/project/modals/pinDisplaying';

import UserProfileActions from 'actions/UserProfileActions';
import UserProfileStore from 'stores/UserProfileStore';
import ChangePassword, { FormModel } from 'page/authorization/login/components/FormChangePassword';

import { URL_LOGOUT, URL_SECURITY_QUESTIONS } from 'constant/path/auth';

const ChangePasswordPage = ({ history }: RouteComponentProps) => {
  const [pin, setPin] = useState<string>('');

  const handleCancel = () => {
    history.replace(URL_LOGOUT);
  };

  const handleSubmit = (model: FormModel) => {
    const userId = UserProfileStore.getUserId();
    const login = UserProfileStore.getLogin();

    return UserProfileActions.loginChangePassword(
      userId,
      login,
      model.password
    ).then((response) => {
      if (response === 'S' || response[0] === 'S') {
        Notification.success('The password has been successfully changed!');

        if (Array.isArray(response) && Boolean(response[1])) {
          setPin(response[1]);
        }
      } else if (response === 'P') {
        Notification.warning(
          'The password should be different from the last password!'
        );
      } else if (response === 'E') {
        Notification.warning('Incorrect old password');
      } else {
        Notification.danger('The password is invalid!');
      }
    });
  };

  const handleCloseDialogPinDisplaying = () => {
    setPin('');

    const user = UserProfileStore.getUser();

    if (user) {
      const isSecurityQuestionFirstSet = typeof user?.sec_ques1 === 'string' && Boolean(user?.sec_ques1.trim());
      const isSecurityQuestionSecondSet = typeof user?.sec_ques2 === 'string' && Boolean(user?.sec_ques2.trim());
      const isSecurityQuestionsSet = isSecurityQuestionFirstSet && isSecurityQuestionSecondSet;

      if (!isSecurityQuestionsSet) {
        history.push(URL_SECURITY_QUESTIONS);
      } else {
        history.push('/');
      }
    } else {
      history.push('/');
    }
  };

  return (
    <div className="small-form">
      <Title title="Change Password" />

      <ChangePassword
        className="row"
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />

      {pin ? (
        <DialogPinDisplaying
          pin={pin}
          onClose={handleCloseDialogPinDisplaying}
        />
      ) : null}
    </div>
  );
};

export default ChangePasswordPage;
