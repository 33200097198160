import { useForm } from 'react-hook-form';

import { Button } from 'components/button';
import { Input } from 'components/form/textField';
import { ControlsLayout } from 'components/layout';
import { validationUserAuthResetPassword as resolver } from './validation';

const defaultValues: FormModel = {
  userEmail: '',
};

export interface FormModel {
  userEmail: string;
}

interface PropsType {
  onSubmit: (data: FormModel) => Promise<any>;
}

const FormUserAuthResetPassword = ({ onSubmit }: PropsType) => {
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues, resolver });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        Please enter the recovery email address you provided when creating
        your account.
      </p>
      <p>
        We'll send you a link to reset your password.
      </p>
      <Input name="userEmail" label="Enter your email" required control={control} />

      <ControlsLayout alignX="center">
        <Button
          type="submit"
          text="Submit"
          disabled={isSubmitting || !isDirty}
        />
      </ControlsLayout>
    </form>
  );
};

export default FormUserAuthResetPassword;
