import { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { LayoutSideTitle } from 'components/layout';
import { SpinnerFixed } from 'components/spinner';
import FormRegion from './components/Form';

import {
  storeRegion,
  RegionType,
} from 'stores/_mobx/systemSetup/masterSetting/region';
import { URL_REGION } from 'constant/path/systemSetup';

interface MatchParams {
  id: string;
}

interface PropsType extends RouteComponentProps<MatchParams> {}

const RegionEditPage = ({ history, match: { params } }: PropsType) => {
  const { fetching, regionDetails } = storeRegion;

  const handleSubmit = (payload: RegionType) =>
    storeRegion
      .modifyRegion(payload as Required<RegionType>)
      .then((response) => {
        if (response) {
          return typeof response === 'boolean' ? null : response;
        }

        history.push(URL_REGION);
      });

  useEffect(() => {
    if (regionDetails === null) {
      history.push(URL_REGION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionDetails]);

  useEffect(() => {
    const id = Number(params.id);

    if (id !== regionDetails.id) {
      storeRegion.getRegionDetails(id);
    }

    return storeRegion.clearRegionDetails;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  return (
    <>
      <LayoutSideTitle title="Edit Region">
        <Link to={URL_REGION} className="btn btn-danger">
          Back
        </Link>
      </LayoutSideTitle>
      <FormRegion defaultValues={regionDetails} onSubmit={handleSubmit} />
      {fetching && <SpinnerFixed />}
    </>
  );
};

export default observer(RegionEditPage);
